<template>
    <div class="live-main-video-player" ref="ryMainVideoLive">
        <div class="live-close-fullscreen-box" @click="handleNormalScreen"></div>
        <div class="live-video-out-box" ref="ryVideoOutBox">
            <div v-show="!isShowVideoBox" @click="showVideoBox" class="live-img-box"
                 :class="{'disable-click' : (cameraList.length === 0 && cameraId === '')}">
                <img src="../../../assets/image/monitor/video_start_img.jpg"/>
                <div v-show="isFullScreen" class="live-video-close-btn" @click.stop="handleNormalScreen">
                    <img src="../../../assets/image/narrow_white.svg"/>
                </div>
            </div>
            <div v-show="isShowVideoBox" class="live-video-box">
                <div v-if="isShowVideo" class="live-video-player-box" ref="playerBox">
                    <video :controls="false" autoplay width="100%" ref="streamVideo" disablePictureInPicture
                           @contextmenu.prevent
                           controlslist="nodownload"/>
                </div>
                <!--                <div class="live-tips" v-if="tips !== ''" v-html="tips" @click_bak="this.restartKvsPlayer">-->
                <!--                </div>-->
                <div v-if="this.tipsType" class="live-tips live-no-video" @click_bak="this.restartKvsPlayer">
                    <div class="img-box" v-if="this.tipsType === 'noSignal'">
                        <div><img src="../../../assets/image/no_signal.svg"/></div>
                        <div>{{$t('liveTracking.unableToConnect')}}</div>
                    </div>
                    <div class="img-box" v-if="this.tipsType === 'loading'">
                        <div><img src="../../../assets/image/video_equipment.svg"/></div>
                        <div>{{$t('liveTracking.connecting')}}</div>
                    </div>
                </div>
                <div v-show="isFullScreen" class="live-video-close-btn vlp-close-success-box"
                     @click="handleNormalScreen">
                    <div class="vlp-img-close-box"><img src="../../../assets/image/narrow_white.svg"/></div>
                </div>
            </div>
            <!--            <div v-if="isShowVideoBox" class="live-close-btn" @click="handleNormalScreen">-->
            <!--                <i class="el-icon-close"></i>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    import Hls from 'hls.js/dist/hls.js';
    import AWS from 'aws-sdk';
    import bus from "../../../utils/bus";
    // import AWSConfig from "../../../assets/js/AWSConfig";

    export default {
        name: "VideoLivePlayer",
        props: {
            openFullScreen: {
                type: Boolean
            },
            cameras: {
                type: Array
            },
            cameraId: {
                type: [String, Number],
                required: true
            },
            deviceId: {
                type: String,
                required: true
            },
            hasSendCommandOn: {
                type: Boolean,
                default: false
            },
            cameraList: {
                type: Array,
                default: ()=>{return [];}
            }
        },
        mounted() {
            bus.$on('stopFleetVideoLive', this.notShowVideoBox);
        },
        beforeDestroy() {
            this.hasCloseFlag = true;
            this.notShowVideoBox();
            if (this.errorConnectKvsTimer) clearTimeout(this.errorConnectKvsTimer);
            if (this.firstConnectKvsTimer) clearTimeout(this.firstConnectKvsTimer);
            if (this.deviceCommandTimer) clearInterval(this.deviceCommandTimer);
            this.clearReloadKvsVideoTimer();
            this.clearKvsUnknownErrorLoadTimer();
            this.clearFragLoadedTimer();
            if (this.player) {
                this.player.destroy();
            }
        },
        data() {
            return {
                credentialsData: null,
                recoverDecodingErrorDate: null,
                recoverSwapAudioCodecDate: null,
                tipsType: null, // loading or noSignal
                replayVideoTimer: null, // setInterval
                player: null,
                isShowVideoBox: false,
                isFullScreen: false,
                isShowVideo: true,
                kvsErrorLoadTryTimes: 0,
                kvsErrorLoadMaxTryTimes: 15,
                kvsErrorLoadTimer: null, // setTimeout
                kvsUnknownErrorLoadTryTimes: 0,
                kvsUnknownErrorLoadMaxTryTimes: 24 * 3600,
                kvsUnknownErrorLoadTimer: null, // setInterval
                deviceCommandTimer: null,   // setInterval
                firstConnectKvsTimer: null, // setTimeout
                errorConnectKvsTimer: null, // setTimeout
                errorSendDeviceCommandOnTimer: null, // setTimeout
                startLoadMediaForOfflineTimer: null, // setTimeout
                startVideoTimer: null, // setTimeout
                loadingMask: null,
                lastHlsErrorTimestamp: null,
                fragLoadedTimer: null, // setTimeout
                hasCloseFlag: false, // 是否收到关闭kvs的信号
            }
        },
        computed: {
            streamName() {
                return this.deviceId + '_' + this.cameraId;
            }
        },
        watch: {
            openFullScreen: function () {
                this.isFullScreen = this.openFullScreen;
            },
            isFullScreen: function () {
                if (this.isFullScreen) {
                    this.handleScreenStyle(true);
                } else {
                    this.handleScreenStyle(false);
                }
            },
            streamName: function () {
                this.stopVideo2();
                this.makeKvsErrorLoadToZero();
                // this.showVideoBox();
            }
        },
        methods: {
            startReloadKvsVideoTimer() {
                if (this.replayVideoTimer === null) {
                    this.replayVideoTimer = setInterval(() => {
                        this.restartKvsPlayer();
                    }, 43200 * 1000);
                }
            },
            restartKvsPlayer() {
                this.clearKvsUnknownErrorLoadTimer();
                this.clearKvsErrorLoadTimer();
                if (this.errorSendDeviceCommandOnTimer) clearTimeout(this.errorSendDeviceCommandOnTimer);
                if (this.firstConnectKvsTimer) clearTimeout(this.firstConnectKvsTimer);
                if (this.errorConnectKvsTimer) clearTimeout(this.errorConnectKvsTimer);
                this.kvsErrorLoadTryTimes = 0;
                this.stopVideo2();
                this.startVideo();
            },
            clearReloadKvsVideoTimer() {
                if (this.replayVideoTimer) {
                    clearInterval(this.replayVideoTimer);
                    this.replayVideoTimer = null;
                }
            },
            clearKvsErrorLoadTimer() {
                if (this.kvsErrorLoadTimer) {
                    clearTimeout(this.kvsErrorLoadTimer);
                    this.kvsErrorLoadTimer = null;
                }
            },
            clearFragLoadedTimer() {
                if (this.fragLoadedTimer) {
                    clearTimeout(this.fragLoadedTimer);
                    this.fragLoadedTimer = null;
                }
            },
            handleKvsErrorLoad() {
                if (!this.isShowVideoBox) return;
                this.kvsErrorLoadTryTimes += 1;
                if (this.kvsErrorLoadTryTimes > this.kvsErrorLoadMaxTryTimes - 1) {
                    if (this.deviceCommandTimer) clearInterval(this.deviceCommandTimer);
                    if (this.kvsErrorLoadTimer) {
                        clearTimeout(this.kvsErrorLoadTimer);
                    }
                    if (this.kvsUnknownErrorLoadTimer) return;
                    this.kvsErrorLoadTimer = setTimeout(() => {
                        this.stopVideo2();
                        this.isShowVideoBox = false;
                    }, 5 * 1000);
                    this.kvsErrorLoadTryTimes = 0;
                    return;
                }
                if (this.startVideoTimer) clearTimeout(this.startVideoTimer);
                this.startVideoTimer = setTimeout(() => {
                    this.startVideo();
                }, 5 * 1000);
            },
            handleKvsUnknownErrorLoad() {
                if (!this.isShowVideoBox) return;
                this.kvsUnknownErrorLoadTryTimes += 1;
                if (this.kvsUnknownErrorLoadTryTimes > this.kvsUnknownErrorLoadMaxTryTimes) {
                    if (this.deviceCommandTimer) clearInterval(this.deviceCommandTimer);
                    this.clearKvsUnknownErrorLoadTimer();
                    this.stopVideo2();
                    this.isShowVideoBox = false;
                    return;
                }
                if (this.kvsUnknownErrorLoadTimer) return;
                this.kvsUnknownErrorLoadTimer = setInterval(() => {
                    this.handleKvsUnknownErrorLoad();
                    this.stopVideo2();
                    this.startVideo();
                }, 30 * 1000);
            },
            handleHlsError() {
                let now = new Date().getTime();
                if (this.lastHlsErrorTimestamp && (now - this.lastHlsErrorTimestamp > 55 * 1000)) {
                    this.lastHlsErrorTimestamp = null;
                    this.handleKvsUnknownErrorLoad();
                    console.error('-----handleHlsError------', 'now:', new Date(now).toLocaleTimeString(), 'last:', new Date(this.lastHlsErrorTimestamp).toLocaleTimeString());
                }
            },
            makeKvsErrorLoadToZero() {
                this.kvsErrorLoadTryTimes = 0;
                this.kvsErrorLoadMaxTryTimes = 15;
                if (this.kvsErrorLoadTimer) {
                    clearTimeout(this.kvsErrorLoadTimer);
                }
                this.kvsErrorLoadTimer = null;
                this.kvsUnknownErrorLoadTryTimes = 0;
                this.kvsUnknownErrorLoadMaxTryTimes = 50;
                if (this.kvsUnknownErrorLoadTimer) {
                    clearInterval(this.kvsUnknownErrorLoadTimer);
                }
                this.kvsUnknownErrorLoadTimer = null;
            },
            clearKvsUnknownErrorLoadTimer() {
                if (this.kvsUnknownErrorLoadTimer) {
                    clearInterval(this.kvsUnknownErrorLoadTimer);
                    this.kvsUnknownErrorLoadTimer = null;
                }
            },
            showVideoBox() {
                if (this.cameraList.length === 0 && this.cameraId === '') return;
                this.$emit('start-play', this.deviceId);
                this.hasCloseFlag = false;
                this.isShowVideoBox = true;
                this.tipsType = 'loading';
                let successCallBack = (data) => {
                    if (data.KVSTTL === 0) {
                        this.isShowVideoBox = false;
                        this.tipsType = null;
                    } else {
                        this.startVideo();
                    }
                };
                let failedCallBack = () => {
                    this.isShowVideoBox = false;
                    this.tipsType = null;
                };
                this.sendDeviceCommandOn(successCallBack, failedCallBack);
            },
            notShowVideoBox() {
                this.$emit('stop-play', this.deviceId);
                this.hasCloseFlag = true;
                this.clearReloadKvsVideoTimer();
                this.clearKvsErrorLoadTimer();
                this.clearKvsUnknownErrorLoadTimer();
                this.clearFragLoadedTimer();
                if (this.errorConnectKvsTimer) {
                    clearTimeout(this.errorConnectKvsTimer);
                    this.errorConnectKvsTimer = null;
                }
                if (this.firstConnectKvsTimer) {
                    clearTimeout(this.firstConnectKvsTimer);
                    this.firstConnectKvsTimer = null;
                }
                if (this.deviceCommandTimer) {
                    clearInterval(this.deviceCommandTimer);
                    this.deviceCommandTimer = null;
                }
                if (this.errorSendDeviceCommandOnTimer) {
                    clearTimeout(this.errorSendDeviceCommandOnTimer);
                    this.errorSendDeviceCommandOnTimer = null;
                }
                if (this.startLoadMediaForOfflineTimer) {
                    clearTimeout(this.startLoadMediaForOfflineTimer);
                    this.startLoadMediaForOfflineTimer = null;
                }
                if (this.startVideoTimer) {
                    clearTimeout(this.startVideoTimer);
                    this.startVideoTimer = null;
                }
                this.kvsErrorLoadTryTimes = 0;
                this.kvsUnknownErrorLoadTryTimes = 0;
                this.isShowVideoBox = false;
                this.tipsType = null;
                this.$emit('sendCommandOn', false);
                this.stopVideo2();
                if (this.$refs.streamVideo)
                    this.$refs.streamVideo.removeEventListener('error', this.handleVideoEvent);
            },
            handleNormalScreen() {
                this.isFullScreen = false;
                this.$emit('normal-screen');
            },
            handleScreenStyle(isFullScreen) {
                if (isFullScreen) {
                    this.$refs.ryMainVideoLive.style.position = 'fixed';
                    this.$refs.ryMainVideoLive.style.top = '0';
                    this.$refs.ryMainVideoLive.style.bottom = '0';
                    this.$refs.ryMainVideoLive.style.left = '0';
                    this.$refs.ryMainVideoLive.style.right = '0';
                    this.$refs.ryVideoOutBox.style.position = 'relative';
                    this.$refs.ryVideoOutBox.style.width = '55%';
                    this.$refs.ryVideoOutBox.style.top = '50%';
                    this.$refs.ryVideoOutBox.style.transform = 'translateY(-50%)';
                } else {
                    this.$refs.ryMainVideoLive.style.position = '';
                    this.$refs.ryMainVideoLive.style.top = '';
                    this.$refs.ryMainVideoLive.style.bottom = '';
                    this.$refs.ryMainVideoLive.style.left = '';
                    this.$refs.ryMainVideoLive.style.right = '';
                    this.$refs.ryVideoOutBox.style.position = '';
                    this.$refs.ryVideoOutBox.style.width = '';
                    this.$refs.ryVideoOutBox.style.top = '';
                    this.$refs.ryVideoOutBox.style.transform = '';
                }
            },
            stopVideo() {
                this.player.stopLoad();
            },
            stopVideo2() {
                if (!this.player) return;
                this.clearFragLoadedTimer();
                if (this.replayVideoTimer) {
                    clearInterval(this.replayVideoTimer);
                    this.replayVideoTimer = null;
                }
                if (this.$refs.streamVideo) this.$refs.streamVideo.pause();
                this.player.stopLoad();
                this.player.destroy();
                this.player = null;
                // this.clearReloadKvsVideoTimer();
            },
            sendDeviceCommandOn(callback, errorCallback) {
                console.log('###### Device Command On ###### time: ', new Date().toLocaleTimeString())
                let successCallback = data => {
                    if (this.hasCloseFlag) return;
                    if (data.KVSTTL === 0) {
                        this.$emit('kvs-no-time');
                        this.handleNormalScreen();
                        this.notShowVideoBox();
                        return;
                    }
                    callback(data);
                };
                let params = {
                    deviceId: this.deviceId,
                    type: 'KVS',
                    command: 'on'
                };
                if (this.cameras && this.cameras.length > 0) {
                    params.cameras = this.cameras;
                } else {
                    params.cameraId = this.cameraId;
                }
                this.$serverApi.device.command(params, successCallback, errorCallback);
            },
            sendDeviceCommandOff() {
                // this.$serverApi.device.command({
                //     deviceId: this.deviceId,
                //     type: 'KVS',
                //     command: 'off'
                // });
            },
            startVideo() {
                let action = () => {
                    if (this.deviceCommandTimer) {
                        clearInterval(this.deviceCommandTimer);
                        this.deviceCommandTimer = setInterval(() => {
                            this.sendDeviceCommandOn();
                        }, 55 * 1000);
                        if (this.errorConnectKvsTimer) clearTimeout(this.errorConnectKvsTimer);
                        this.errorConnectKvsTimer = setTimeout(() => {
                            this.startKvsPlayer();
                        }, 1000);
                        return;
                    }
                    if (this.hasSendCommandOn) {
                        if (this.firstConnectKvsTimer) clearTimeout(this.firstConnectKvsTimer);
                        this.firstConnectKvsTimer = setTimeout(() => {
                            this.startKvsPlayer();
                        }, 3000);
                        return;
                    }
                    this.sendDeviceCommandOn(() => {
                        if (this.deviceCommandTimer) clearInterval(this.deviceCommandTimer);
                        this.deviceCommandTimer = setInterval(() => {
                            this.sendDeviceCommandOn();
                        }, 55 * 1000);
                        this.$emit('sendCommandOn', true);
                        if (this.firstConnectKvsTimer) clearTimeout(this.firstConnectKvsTimer);
                        this.firstConnectKvsTimer = setTimeout(() => {
                            this.startKvsPlayer();
                        }, 3000);
                    }, () => {
                        this.tipsType = 'noSignal';
                        this.errorSendDeviceCommandOnTimer = setTimeout(() => {
                            this.isShowVideoBox = false;
                        }, 3 * 1000);
                    });
                };
                this.getCredentials().then(() => {
                    action();
                }).catch(() => {
                    this.tipsType = 'noSignal';
                    this.errorSendDeviceCommandOnTimer = setTimeout(() => {
                        this.isShowVideoBox = false;
                    }, 3 * 1000);
                });
            },
            handleMediaError() {
                console.log('handleMediaError');
                let now = new Date().getTime();
                if (!this.recoverDecodingErrorDate || (now - this.recoverDecodingErrorDate) > 3000) {
                    this.recoverDecodingErrorDate = new Date().getTime();
                    console.log('this.player.recoverMediaError()');
                    if (this.player) this.player.recoverMediaError();
                } else {
                    if (!this.recoverSwapAudioCodecDate || (now - this.recoverSwapAudioCodecDate) > 3000) {
                        this.recoverSwapAudioCodecDate = new Date().getTime();
                        console.log('this.player.swapAudioCodec()');
                        if (this.player) {
                            this.player.swapAudioCodec();
                            this.player.recoverMediaError();
                        }
                    }
                }
            },
            handleFragLoadedEvent() {
                if (this.fragLoadedTimer) {
                    clearTimeout(this.fragLoadedTimer);
                    this.fragLoadedTimer = null;
                }
                this.fragLoadedTimer = setTimeout(() => {
                    this.sendDeviceCommandOn();
                    this.handleKvsUnknownErrorLoad();
                    console.error('----handleFragLoadedEvent---', new Date().toLocaleTimeString());
                }, 60 * 1000);
            },
            handleVideoEvent(evt) {
                if (evt.type === 'error') {
                    console.log('Video Error', evt);
                    let mediaError = evt.currentTarget.error;
                    if (mediaError.code === mediaError.MEDIA_ERR_DECODE)
                        this.handleMediaError();
                }
            },
            openLoadingMask() {
                let playerBoxDom = this.$refs.playerBox;
                if (!this.loadingMask && playerBoxDom) {
                    this.loadingMask = this.$loading({
                        target: playerBoxDom,
                        background: 'rgba(0, 0, 0, 0)'
                    });
                }
            },
            closeLoadingMask() {
                if (this.loadingMask) {
                    this.loadingMask.close();
                    this.loadingMask = null;
                }
            },
            startKvsPlayer() {
                let config = {
                    liveBackBufferLength: 0,
                    // manifestLoadingTimeOut: 10000,
                    // manifestLoadingMaxRetry: 100,
                    // manifestLoadingRetryDelay: 1000,
                    // manifestLoadingMaxRetryTimeout: 64000,
                    // fragLoadingTimeOut: 200000,
                    // fragLoadingMaxRetry: 60,
                    // fragLoadingRetryDelay: 1000,
                    // fragLoadingMaxRetryTimeout: 640000,
                    // debug: true,
                };
                this.player = new Hls(config);
                this.startReloadKvsVideoTimer();
                let streamName = this.streamName;
                // Step 1: Configure SDK Clients
                let options = {
                    accessKeyId: this.credentialsData.Credentials.AccessKeyId, // AWSConfig.kvs.accessKeyId,
                    secretAccessKey: this.credentialsData.Credentials.SecretAccessKey, // AWSConfig.kvs.secretAccessKey,
                    sessionToken: this.credentialsData.Credentials.SessionToken, // AWSConfig.kvs.sessionToken,
                    region: this.credentialsData.Region, // AWSConfig.kvs.region,
                    endpoint: undefined, // AWSConfig.kvs.endpoint
                };
                const kinesisVideo = new AWS.KinesisVideo(options);
                const kinesisVideoArchivedContent = new AWS.KinesisVideoArchivedMedia(options);
                // Step 2: Get a data endpoint for the stream
                console.log('&&&&&&& before getDataEndpoint &&&&&&&&&', new Date().toLocaleTimeString())
                kinesisVideo.getDataEndpoint({
                    StreamName: streamName,//D710_JP_Front
                    APIName: "GET_HLS_STREAMING_SESSION_URL"	//"GET_HLS_STREAMING_SESSION_URL"
                }, (err, response) => {
                    console.log('&&&&&&& after success getDataEndpoint &&&&&&&&&', new Date().toLocaleTimeString())
                    if (err) {
                        if (!this.kvsUnknownErrorLoadTimer) {
                            // this.tipsType = 'noSignal';
                            this.tipsType = 'loading';
                        }
                        console.log('&&&&&&& failed getDataEndpoint &&&&&&&&&', new Date().toLocaleTimeString())
                        this.sendDeviceCommandOn();
                        this.handleKvsErrorLoad();
                        return;
                    }
                    kinesisVideoArchivedContent.endpoint = new AWS.Endpoint(response.DataEndpoint);
                    // Step 3: Get a Streaming Session URL
                    console.log('&&&&&&& before getHLSStreamingSessionURL &&&&&&&&&', new Date().toLocaleTimeString())
                    kinesisVideoArchivedContent.getHLSStreamingSessionURL({
                        StreamName: streamName,//D710_JP_Front
                        PlaybackMode: "LIVE",//LIVE
                        HLSFragmentSelector: {
                            FragmentSelectorType: "PRODUCER_TIMESTAMP",//SERVER_TIMESTAMP(适合ALWAYS ON_DISCONTINUITY) PRODUCER_TIMESTAMP(适合ON_DISCONTINUITY NEVER)
                            // TimestampRange: undefined
                        },
                        ContainerFormat: "MPEG_TS",//FRAGMENTED_MP4 MPEG_TS
                        DiscontinuityMode: "ON_DISCONTINUITY",//ALWAYS ON_DISCONTINUITY NEVER
                        DisplayFragmentTimestamp: "ALWAYS",//NEVER
                        MaxMediaPlaylistFragmentResults: null, //parseInt(""),//空 => NaN
                        Expires: parseInt("43200")//空 => NaN
                    }, (err, response) => {
                        console.log('&&&&&&& after success getHLSStreamingSessionURL &&&&&&&&&', new Date().toLocaleTimeString())
                        if (err) {
                            console.log('&&&&&&& getHLSStreamingSessionURL &&&&&&&&&', new Date().toLocaleTimeString())
                            // this.tipsType = 'noSignal';
                            this.tipsType = 'loading';
                            this.sendDeviceCommandOn();
                            this.handleKvsErrorLoad();
                            return;
                        }
                        console.log('&&&&&&& Get playlist &&&&&&&&&', response.HLSStreamingSessionURL, new Date().toLocaleTimeString())
                        // Step 4: Give the URL to the video player.
                        let playerElement = this.$refs.streamVideo;
                        console.log('&&&&&&& before load playlist &&&&&&&&&', new Date().toLocaleTimeString())
                        this.player.loadSource(response.HLSStreamingSessionURL);
                        console.log('&&&&&&& after load playlist and before attach media &&&&&&&&&', new Date().toLocaleTimeString())
                        this.player.attachMedia(playerElement);
                        console.log('&&&&&&& after attach media &&&&&&&&&', new Date().toLocaleTimeString())
                        this.player.on(Hls.Events.MANIFEST_PARSED, () => {
                            console.log('****** Hls.Events.MANIFEST_PARSED', new Date().toLocaleTimeString())
                            this.clearKvsUnknownErrorLoadTimer();
                            this.kvsUnknownErrorLoadTryTimes = 0;
                            this.makeKvsErrorLoadToZero();
                            playerElement.play();
                            this.$refs.streamVideo.removeEventListener('error', this.handleVideoEvent);
                            this.$refs.streamVideo.addEventListener('error', this.handleVideoEvent);

                            this.$refs.streamVideo.removeEventListener('seeked', this.handleSeeked);
                            this.$refs.streamVideo.addEventListener('seeked', this.handleSeeked);
                            this.$refs.streamVideo.removeEventListener('seeking', this.handleSeeking);
                            this.$refs.streamVideo.addEventListener('seeking', this.handleSeeking);
                            this.$refs.streamVideo.removeEventListener('waiting', this.handleWaiting);
                            this.$refs.streamVideo.addEventListener('waiting', this.handleWaiting);
                        });
                        this.player.on(Hls.Events.FRAG_LOADED, () => {
                            console.log('****** Hls.Events.FRAG_LOADED', new Date().toLocaleTimeString())
                            this.tipsType = null;
                            this.lastHlsErrorTimestamp = null;
                            this.closeLoadingMask();
                            this.handleFragLoadedEvent();  // setTimeout 60s
                        });
                        this.player.on(Hls.Events.ERROR, (event, data) => {
                            this.openLoadingMask();
                            console.error('****** Hls.Events.ERROR', new Date().toLocaleTimeString(), 'event:', event, 'data:', data);
                            if (data.fatal) {
                                if (this.lastHlsErrorTimestamp === null)
                                    this.lastHlsErrorTimestamp = new Date().getTime();
                                this.handleHlsError(); // 55s handleFragLoadedEvent也存在异常的处理逻辑 60s
                                switch (data.type) {
                                    case Hls.ErrorTypes.NETWORK_ERROR:
                                        // this.tipsType = 'noSignal';
                                        this.tipsType = null;
                                        // this.player.startLoad();
                                        this.player.detachMedia();
                                        this.player.loadSource(response.HLSStreamingSessionURL);
                                        this.player.attachMedia(playerElement);
                                        break;
                                    case Hls.ErrorTypes.MEDIA_ERROR:
                                        this.handleMediaError();
                                        // this.handleKvsUnknownErrorLoad();
                                        // this.tipsType = 'noSignal';
                                        this.tipsType = null;
                                        break;
                                    default:
                                        this.handleKvsUnknownErrorLoad();
                                        // this.tipsType = 'noSignal';
                                        this.tipsType = null;
                                        break;
                                }
                            } else {
                                switch (data.type) {
                                    case Hls.ErrorTypes.NETWORK_ERROR:
                                        // if (this.startLoadMediaForOfflineTimer) clearTimeout(this.startLoadMediaForOfflineTimer);
                                        // this.startLoadMediaForOfflineTimer = setTimeout(() => {
                                        //     this.player.startLoad();
                                        // }, 5000);
                                        break;
                                    case Hls.ErrorTypes.MEDIA_ERROR:
                                        // this.handleMediaError();
                                        // this.player.swapAudioCodec();
                                        // this.player.recoverMediaError();
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });
                        this.tipsType = null;
                    });
                });
            },

            handleSeeked(e) {
                this.testPrint(e, 'Seeked')
            },
            handleSeeking(e) {
                this.testPrint(e, 'Seeking')
            },
            handleWaiting(e) {
                this.testPrint(e, 'Waiting')
            },
            getCredentials() {
                let promise = (resolve, reject) => {
                    let currentTime = new Date().getTime() + 30 * 1000;
                    currentTime = parseInt(currentTime / 1000);
                    if (this.credentialsData && currentTime < this.credentialsData.ExpiryTimestamp) {
                        resolve(this.credentialsData);
                    } else {
                        this.$serverApi.credentials.credentials({ServiceType: "KVS"}, data => {
                            this.credentialsData = data;
                            resolve(data);
                        }, error => {
                            reject(error);
                        });
                    }
                };
                return new Promise(promise);
            },
            testPrint(e, tag) {
                let time = new Date().toLocaleTimeString();
                console.log(`$$$$$$$$$ type: ${tag}, time: ${time}, event:`, e)
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .live-main-video-player
        background-color_bak rgba(0, 0, 0, .4)
        z-index 1010

        .live-close-fullscreen-box
            position absolute
            top 0
            bottom 0
            left 0
            right 0
            background-color rgba(0, 0, 0, .4)
            z-index -1
        .live-video-out-box
            margin 0 auto

            .live-close-btn
                position absolute
                top -40px
                right -40px
                color $color_white
                font-size 40px

            .live-close-btn:hover
                color red
                cursor pointer

        .live-no-video
            background-color $color_gray_first !important
            color $color_black_third !important
            height 100%
            position relative
            overflow hidden

            .img-box
                position_bak position
                padding-top 21%
                text-align center

                img
                    width 15%

        .live-img-box
            position relative
            height_bak 212px
            cursor pointer

            img
                width 100%

            .live-video-close-btn
                position absolute
                width 20px
                height 20px
                right 30px
                bottom 30px
                cursor pointer
        .disable-click
            cursor not-allowed

        .live-video-box
            position relative

            .live-video-player-box
                min-height 170px
                background-color_bak $color_black_third

            .live-tips
                position absolute
                background-color $color_white
                top 0
                left 0
                right 0
                bottom 0
                text-align center
                line-height 20px
                cursor default
                color red

            .live-video-close-btn
                position absolute
                width 50px
                height 50px
                right 0
                bottom 0
                cursor pointer

            .vlp-close-success-box
                width 96px
                height 96px

                .vlp-img-close-box
                    background-color rgba(60, 77, 215, .5)
                    width 72px
                    height 72px
                    img
                        position relative
                        top 20px
                        left 20px
                        width 32px
                        height 32px
                .vlp-img-close-box:hover
                    background-color rgba(60, 77, 215, 1)
</style>

<template>
    <div class="speed-fuel-gps">
        <div v-if="!onRoad" class="sfg-row">
            <div class="sfg-left"><span class="sfg-icon via-hours"/>{{ $dateFormat.dateTime(timestamp, mixin_dateTimeFormat, mixin_timezone) }}</div>
            <!--div class="sfg-right"><span class="sfg-icon via-fuel"/>{{ formatDataAndUnit(fuel, fuelUnit) }}</div-->
        </div>
        <div v-else class="sfg-row">
            <div class="sfg-left"><span class="sfg-icon via-speedometer"/>{{ formatDataAndUnit(speed, speedUnit) }}</div>
            <!--div class="sfg-right"><span class="sfg-icon via-fuel"/>{{ formatDataAndUnit(fuel, fuelUnit) }}</div-->
        </div>
        <div class="sfg-location " :title="location">
            <div><span class="via-map-marker sfg-icon"></span></div>
            <div class="sfg-location-value sfg-word-show">{{ location }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SpeedFuelGpsBox",
        props: {
            onRoad: {
                type: Boolean,
                required: true
            },
            speed: {
                type: [String, Number],
            },
            speedUnit: {
                type: String,
                required: true
            },
            fuel: {
                type: [String, Number],
                required: true
            },
            fuelUnit: {
                type: String,
                required: true
            },
            location: {
                type: String,
                required: true
            },
            timestamp: {
                type: Number,
            },
        },
        methods: {
            formatDate(timestamp) {
                if (timestamp === 0 || isNaN(timestamp)) return this.$t('globalText.na');
                let date = new Date(timestamp * 1000),
                    year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate(),
                    hours = date.getHours(),
                    minutes = date.getMinutes(),
                    seconds = date.getSeconds();
                if (year === 1970) return this.$t('globalText.na');
                day = parseInt(day) < 10 ? '0' + parseInt(day) : '' + parseInt(day);
                month = parseInt(month) < 10 ? '0' + parseInt(month) : '' + parseInt(month);
                day = parseInt(day) < 10 ? '0' + parseInt(day) : '' + parseInt(day);
                hours = parseInt(hours) < 10 ? '0' + parseInt(hours) : '' + parseInt(hours);
                minutes = parseInt(minutes) < 10 ? '0' + parseInt(minutes) : '' + parseInt(minutes);
                seconds = parseInt(seconds) < 10 ? '0' + parseInt(seconds) : '' + parseInt(seconds);
                return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            },
            formatDataAndUnit(value, unit) {
                if (value === true) return this.$t('globalText.na');
                if ((value && value !== 'n/a' && value !== 'N/A') || value === 0) {
                    return value + ' ' + unit;
                }
                return this.$t('globalText.na');
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $box-padding-right = 12px
    $box-padding-left = 12px
    .speed-fuel-gps
        padding-left $box-padding-left
        padding-right $box-padding-right
        padding-top 15px
        padding-bottom 15px

        > .sfg-row
            cursor default
            height 30px;

            > .sfg-left, > .sfg-right
                display inline-block

            > .sfg-right
                text-align right;
                float right
                margin-right 10px

            > .sfg-left
                float left
                line-height 14px

        .sfg-icon
            width 22px
            display inline-block
            text-align left

        .sfg-location
            display flex
            justify-content space-between
            line-height 20px

        .sfg-word-show
            word-break keep-all
            word-wrap break-word
        .sfg-location-value
             width calc(100% - 22px)
             overflow hidden
             height auto
             text-overflow: ellipsis;
             display: -webkit-box;
             -webkit-line-clamp: 3;
             -webkit-box-orient: vertical;
</style>

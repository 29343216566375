<template>
    <div class="ry-com-leaflet-map common-leaflet-map">
        <div class="ry-map map-box" id="ryLeafletMap" ref="ryLeafletMap"></div>
        <preview-dialog v-model="isShowPreviewDialog" :img-path="previewImgUrl" :isError="isLoadImgError">
        </preview-dialog>
    </div>
</template>

<script>
    import L from 'leaflet'
    import 'leaflet/dist/leaflet.css'
    import PreviewDialog from "@/components/common/PreviewDialog";

    const A_COLLISION_VALUE = 'Collision';
    const A_PARKING_MODE_COLLISION_VALUE = 'ParkingCollision';
    const A_DRIVER_ALERT_VALUE = 'DriverAlert';
    const A_UNPERMITTED_DRIVER_VALUE = 'Unpermitted';
    const A_ALL_VALUE = 'All';

    export default {
        name: "ComLeafletMap",
        components: {PreviewDialog},
        props: {
            type: {type: String, default: 'google'},
            zoom: {type: Number, default: 10},
            zoomCenter: {type: Object},// 用于修改zoom
            center: {required: true, type: Object},
            revvingUpFlags: {type: Array},     // 急转弯图标
            hardBrakingFlags: {type: Array},   // 急刹车图标
            hardCorneringFlags: {type: Array}, // 急转弯图标
            LDWFlags: {type: Array},
            FCWFlags: {type: Array},
            driverFatigueFlags: {type: Array},
            distractedDrivingFlags: {type: Array},
            smokingFlags: {type: Array},
            phoneUsageFlags: {type: Array},
            path: {type: [Array, Object]}, // 行车的轨迹线  {data: array, fitBounds: false}
            boundaryPath: {type: Array}, // 给定的坐标同时出现在地图上并以尽可能大的方式展示边界
            start: {type: Object}, // 行车的起始位置坐标
            end: {type: Object},   // 行车的结束位置坐标
            collisions: {type: Array},        // 碰撞的位置信息
            parkingAlert: {type: Array},      // Parking Alert的位置信息
            driverAlert: {type: Array},       // Driver Alert的位置信息
            unpermittedDriver: {type: Array}, // Unpermitted Driver的位置信息
            targetCollision: {type: Object}, // 选中的碰撞信息id（EventId）
            cars: {type: Array}, // 正在行驶的汽车
            targetCar: {type: Object},
            autoBoundary: {type: Array},
            driverCallFlag: {type: Array},
            headquarterCallFlag: {type: Array},
            /*alertImageArray: {
                type: Array,
                default: () => [//
                    'http://dev.video.mobile360.viatech.com/DriverPicture/30660DEA72103B3B1ADCABD77755554E1606374624.jpg',
                    'http://dev.video.mobile360.viatech.com/DriverPicture/D8BF59728FEF60AB290DBC3D7D1D92861606288849.jpg',
                    'http://dev.video.mobile360.viatech.com/DriverPicture/1BD74B6ADD522E0095F48A7983515AEE1606373171.jpg'
                ]
            }*/
        },
        created() {
            window.onClickAlertImg = (data) => {
                let src = document.getElementById('alertImgId').src;
                if (src.indexOf('AlertImgDefaultPic') === -1) {
                    this.previewImgUrl = this.imgsArrMap.get(data)[this.currentImgIndex];
                    this.isLoadImgError = false;
                }else {
                    this.previewImgUrl = '';
                    this.isLoadImgError = true;
                }
                this.isShowPreviewDialog = true;

            };
            window.onClickLeftArrow = (key) => {
                if (this.currentImgIndex === 0) {
                    this.currentImgIndex = this.imgsArrMap.get(key).length - 1;
                } else {
                    this.currentImgIndex = --this.currentImgIndex;
                }
                document.getElementById('alertImgId').src = this.imgsArrMap.get(key)[this.currentImgIndex];
                document.getElementById('alertImgBottom').innerHTML = this.currentImgIndex + 1 + "/" + this.imgsArrMap.get(key).length;
            };
            window.onClickRightArrow = (key) => {
                if (this.currentImgIndex === this.imgsArrMap.get(key).length - 1) {
                    this.currentImgIndex = 0;
                } else {
                    this.currentImgIndex = ++this.currentImgIndex;
                }
                document.getElementById('alertImgId').src = this.imgsArrMap.get(key)[this.currentImgIndex];
                document.getElementById('alertImgBottom').innerHTML = this.currentImgIndex + 1 + "/" + this.imgsArrMap.get(key).length;
            };
            window.onImgError = () => {
                //console.error(`监听到图片加载出错`);
                document.getElementById('alertImgId').src = `${require('../../assets/image/AlertImgDefaultPic.png')}`;
            };
            window.onMouseOver = (key) => {
                if (this.imgsArrMap.get(key).length === 1) return;//只有一张图片时不显示箭头
                document.getElementById('leftArrow').className = 'left-arrow';
                document.getElementById('rightArrow').className = 'right-arrow'
            };
            window.onMouseLeave = () => {
                document.getElementById('leftArrow').className = 'arrow_display';
                document.getElementById('rightArrow').className = 'arrow_display'
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initMap();
                // this.autoLocate();
                this.initAllMapTags();
                // bus.$on('redrawAllMapTags', this.redrawMap);
                this.initUnitType();
            })
        },
        activated() {
            this.$nextTick(() => {
                this.initUnitType();
                if (this.zoomInstance) {
                    this.zoomInstance.remove();
                }
                let language = this.$store.getters['i18n/localLanguage'];
                let temp = 'en';
                if (language.startsWith('en')) {
                    temp = 'en'
                }else {
                    temp = language;
                }
                let url = "http://{s}.google.com/maps/vt?&hl=" + temp + "&x={x}&y={y}&z={z}";
                L.tileLayer(
                    url,
                    {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3', 'www'],
                    },
                ).addTo(this.map);
                this.zoomInstance = L.control.zoom({position: 'topright',zoomInTitle:this.$t('liveTracking.zoomIn'),zoomOutTitle:this.$t('liveTracking.zoomOut')});
                this.zoomInstance.addTo(this.map);
            });

        },
        beforeDestroy() {
            this.removeAllMapTags();
            this.map.remove();
        },
        data() {
            return {
                zoomInstance:null,
                isLoadImgError: false,
                imgsArrMap: new Map(),
                previewImgUrl: '',
                alertImageArray: [],
                fullScreen: true,
                isShowPreviewDialog: false,
                currentImgIndex: 0,
                map: null,
                scaleControl: null,
                revvingUp: { // 急加速
                    location: this.revvingUpFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                hardBraking: { // 急加速
                    location: this.hardBrakingFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                hardCornering: { // 急转弯
                    location: this.hardCorneringFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                LDW: {
                    location: this.LDWFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                FCW: {
                    location: this.FCWFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                driverFatigue: {
                    location: this.driverFatigueFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                distractedDriving: {
                    location: this.distractedDrivingFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                smoking: {
                    location: this.smokingFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                phoneUsage: {
                    location: this.phoneUsageFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                historyPath: {
                    location: this.path || [], // {lng: 47.51, lat: -124.68},
                    layer: null,
                    fitBounds: true // 路线自动适配屏幕的大小
                },
                maxBoundaryPath: {
                    location: this.boundaryPath || [], // {lng: 47.51, lat: -124.68},
                    layer: null
                },
                startLocation: {
                    location: this.start || {}, // {lng: 47.51, lat: -124.68},
                    layer: null
                },
                endLocation: {
                    location: this.end || {}, // {lng: 47.51, lat: -124.68},
                    layer: null
                },
                collisionsCars: {
                    location: this.collisions || [], // {lng: 47.51, lat: -124.68, carId: 111 }
                    layers: new Map()
                },
                parkingAlertEvent: {
                    location: this.collisions || [], // {lng: 47.51, lat: -124.68, carId: 111 }
                    layers: new Map()
                },
                driverAlertEvent: {
                    location: this.collisions || [], // {lng: 47.51, lat: -124.68, carId: 111 }
                    layers: new Map()
                },
                unpermittedDriverEvent: {
                    location: this.collisions || [], // {lng: 47.51, lat: -124.68, carId: 111 }
                    layers: new Map()
                },
                targetCollisionCar: {
                    type: null,
                    eventId: null,
                    center: null
                },
                carsLocation: {
                    location: this.cars || [], // {lng: 47.51, lat: -128.68, carId: 111, color: 'red' }
                    layers: new Map()
                },
                targetCarLocation: {
                    location: this.targetCar || {}, // {lng: 54.51, lat: -132.68, carId: 444, color: 'red' }
                    layer: null
                },
                autoBoundaryPath: {
                    location: this.autoBoundary || [], // {lng: 47.51, lat: -124.68},
                    layer: null
                },
                driverCall: {
                    location: this.hardBrakingFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                },
                headquarterCall: {
                    location: this.hardBrakingFlags || [], // { lng: 38.77, lat:-122.43, carId: 299, time: '2019-10-13 19:12:23', speed: '60', address: '地址', name: '' }
                    layers: new Map()
                }
            }
        },
        computed: {
            collisionsCarsSpeed() {
                let location = this.collisionsCars.location[0];
                if (location && location.speed && !isNaN(location.speed)) return location.speed;
                return 0;
            },
            currentUnitType() {
                return this.$store.getters.unit;
            },
            currentLanguage(){
                return this.$store.getters['i18n/localLanguage'];
            }
        },
        watch: {
            currentLanguage(value){
                this.removeStartLocation();
                this.initStartLocation();
                this.removeEndLocation();
                this.initEndLocation();
            },
            currentUnitType() {
                this.initUnitType();
            },
            zoomCenter(params) {
                if (typeof params.value !== 'number' || params.value < 0 || params.value > 18 || !params.center) return;
                this.map.setView(new L.latLng(params.center.lat, params.center.lng), params.value);
            },
            center(value) {
                this.changeMapCenter(value.lat, value.lng);
            },
            revvingUpFlags(value) {
                this.revvingUp.location = value;
                this.removeRevvingUpFlags();
                this.initRevvingUpFlags();
            },
            hardBrakingFlags(value) {
                this.hardBraking.location = value;
                this.removeHardBrakingFlags();
                this.initHardBrakingFlags();
            },
            hardCorneringFlags(value) {
                this.hardCornering.location = value;
                this.removeHardCorneringFlags();
                this.initHardCorneringFlags();
            },
            LDWFlags(value) {
                this.LDW.location = value;
                this.removeLDWFlags();
                this.initLDWFlags();
            },
            FCWFlags(value) {
                this.FCW.location = value;
                this.removeFCWFlags();
                this.initFCWFlags();
            },
            driverFatigueFlags(value) {
                this.driverFatigue.location = value;
                this.removeDriverFatigueFlags();
                this.initDriverFatigueFlags();
            },
            distractedDrivingFlags(value) {
                this.distractedDriving.location = value;
                this.removeDistractedDrivingFlags();
                this.initDistractedDrivingFlags();
            },
            smokingFlags(value) {
                this.smoking.location = value;
                this.removeSmokingFlags();
                this.initSmokingFlags();
            },
            phoneUsageFlags(value) {
                this.phoneUsage.location = value;
                this.removePhoneUsageFlags();
                this.initPhoneUsageFlags();
            },
            path(value) {
                if (value.data) {
                    this.historyPath.location = value.data;
                    this.historyPath.fitBounds = !!value.fitBounds;
                } else {
                    this.historyPath.location = value;
                    this.historyPath.fitBounds = true;
                }
                this.removeHistoryPath();
                this.initHistoryPath();
            },
            boundaryPath(value) {
                this.maxBoundaryPath.location = value;
                this.removeBoundaryPath();
                this.initBoundaryPath();
            },
            start(value) {
                this.startLocation.location = value;
                this.removeStartLocation();
                this.initStartLocation();
            },
            end(value) {
                this.endLocation.location = value;
                this.removeEndLocation();
                this.initEndLocation();
            },
            collisions(value) {
                this.collisionsCars.location = value;
                this.removeCollisionsCars();
                this.initCollisionsCars();
            },
            parkingAlert(value) {
                this.parkingAlertEvent.location = value;
                this.removeParkingAlertEvent();
                this.initParkingAlertEvent();
            },
            driverAlert(value) {
                this.driverAlertEvent.location = value;
                this.removeDriverAlertEvent();
                this.initDriverAlertEvent();
            },
            unpermittedDriver(value) {
                this.unpermittedDriverEvent.location = value;
                this.removeUnpermittedDriverEvent();
                this.initUnpermittedDriverEvent();
            },
            targetCollision() {
                this.removeTargetCollisionCar();
                this.initTargetCollisionCar();
            },
            cars(value) {
                this.carsLocation.location = value;
                this.removeCarsLocation();
                this.initCarsLocation();
                // this.checkFirstCarBound();
            },
            targetCar(value) {
                this.targetCarLocation.location = value;
                this.removeTargetCarLocation();
                this.initTargetCarLocation();
                // if (this.map && typeof value.lat !== 'undefined') {
                //     this.map.setZoom(this.zoom);
                // }
            },
            autoBoundary(value) {
                this.autoBoundaryPath.location = value;
                this.removeAutoBoundaryPath();
                this.initAutoBoundaryPath();
            },
            driverCallFlag(value) {
                this.driverCall.location = value;
                this.removeDriverCallFlags();
                this.initDriverCallFlags();
            },
            headquarterCallFlag(value) {
                this.headquarterCall.location = value;
                this.removeHeadquarterCallFlags();
                this.initHeadquarterCallFlags();
            },
        },
        methods: {
            autoLocate() {
                this.map.locate({
                    setView: false
                });
                // this.map.on('locationfound', e => {
                //     let lat = e.latitude;
                //     let lng = e.longitude;
                // if (1) {
                //     this.changeMapCenter(lat, lng)
                // }
                // });
                // this.map.on('locationerror', function (e) {
                // });
            },
            redrawMap() {
                this.removeAllMapTags();
                this.initAllMapTags();
            },
            initAllMapTags() {
                this.initRevvingUpFlags();
                this.initHardBrakingFlags();
                this.initHardCorneringFlags();
                this.initLDWFlags();
                this.initFCWFlags();
                this.initDriverFatigueFlags();
                this.initDistractedDrivingFlags();
                this.initSmokingFlags();
                this.initPhoneUsageFlags();
                this.initHistoryPath();
                this.initBoundaryPath();
                this.initStartLocation();
                this.initEndLocation();
                this.initCollisionsCars();
                this.initParkingAlertEvent();
                this.initDriverAlertEvent();
                this.initUnpermittedDriverEvent();
                this.initTargetCollisionCar();
                this.initCarsLocation();
                this.initTargetCarLocation();
                this.initDriverCallFlags();
                this.initHeadquarterCallFlags();
            },
            removeAllMapTags() {
                this.removeRevvingUpFlags();
                this.removeHardBrakingFlags();
                this.removeHardCorneringFlags();
                this.removeLDWFlags();
                this.removeFCWFlags();
                this.removeDriverFatigueFlags();
                this.removeDistractedDrivingFlags();
                this.removeSmokingFlags();
                this.removePhoneUsageFlags();
                this.removeHistoryPath();
                this.removeBoundaryPath();
                this.removeStartLocation();
                this.removeEndLocation();
                this.removeCollisionsCars();
                this.removeDriverAlertEvent();
                this.removeParkingAlertEvent();
                this.removeUnpermittedDriverEvent();
                this.removeTargetCollisionCar();
                this.removeCarsLocation();
                this.removeTargetCarLocation();
                this.removeDriverCallFlags();
                this.removeHeadquarterCallFlags();
            },
            changeMapCenter(lat, lng) {
                this.map.setView(new L.latLng(lat, lng));
            },
            initMap() {
                let latlng = L.latLng(this.center.lat, this.center.lng);
                //地图西南方（左下角）控制点
                const leftBottom = L.latLng(-83.2005, -300);
                //地图东北方（右上角）控制点
                const rightTop = L.latLng(84.8658, 700);
                const bounds = L.latLngBounds(leftBottom, rightTop);
                this.map = L.map(this.$refs.ryLeafletMap, {
                    // center: [this.center.lng, this.center.lat],
                    center: latlng,
                    zoom: this.zoom,
                    zoomControl: false,
                    attributionControl: false,
                    minZoom: 2,
                    keyboard: !(/Android|webOS|iPhone|iPad|iPod|Mac OS|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)),
                });
                let language = this.$store.getters['i18n/localLanguage'];
                let temp = 'en';
                if (language.startsWith('en')) {
                    temp = 'en'
                }else {
                    temp = language;
                }
                let url = "http://{s}.google.com/maps/vt?&hl=" + temp + "&x={x}&y={y}&z={z}";
                L.tileLayer(
                    url,
                    {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3', 'www'],
                    },
                ).addTo(this.map);
                this.map.setMaxBounds(bounds);
                this.zoomInstance = L.control.zoom({position: 'topright',zoomInTitle:this.$t('liveTracking.zoomIn'),zoomOutTitle:this.$t('liveTracking.zoomOut')});
                this.zoomInstance.addTo(this.map);

            },
            initUnitType() {
                let unit = this.currentUnitType;
                let eleArr = document.getElementsByClassName("leaflet-control-scale-line");
                if (eleArr.length > 0) {
                    eleArr.forEach(element => {
                        element.style.display = "none";
                    })
                }
                if (unit && unit.toUpperCase() === "METRIC") {
                    L.control.scale({position: 'bottomright', metric: true, imperial: false}).addTo(this.map);
                } else {
                    L.control.scale({position: 'bottomright', metric: false, imperial: true}).addTo(this.map);
                }
                let element = document.getElementsByClassName("leaflet-control-scale-line")[0];
                let elementTop = document.getElementsByClassName("leaflet-control-zoom")[0];
                let oldStyle = element.style.cssText;
                let oldStyleTop = elementTop.style.cssText;
                elementTop.style.cssText = oldStyleTop + "position:fixed;top:80px;right:10px";
                element.style.cssText = oldStyle + "text-align:center;position:fixed;bottom:10px;right:20px";
            },
            checkValidBound(lng, lat, top, right, bottom, left) { // top, right, bottom, left 为0到1的小数
                let east = this.map.getBounds().getEast();
                let west = this.map.getBounds().getWest();
                let south = this.map.getBounds().getSouth();
                let north = this.map.getBounds().getNorth();

                let width = east * Math.pow(10, 15) - west * Math.pow(10, 15);
                let height = north * Math.pow(10, 15) - south * Math.pow(10, 15);

                let topOffset = Math.floor(height * Math.floor(top * 100) / 100);
                let bottomOffset = Math.floor(height * Math.floor(bottom * 100) / 100);
                let leftOffset = Math.floor(width * Math.floor(left * 100) / 100);
                let rightOffset = Math.floor(width * Math.floor(right * 100) / 100);

                let newNorth = (north * Math.pow(10, 15) - topOffset) / Math.pow(10, 15);
                let newSouth = (south * Math.pow(10, 15) + bottomOffset) / Math.pow(10, 15);
                let newWest = (west * Math.pow(10, 15) + leftOffset) / Math.pow(10, 15);
                let newEast = (east * Math.pow(10, 15) - rightOffset) / Math.pow(10, 15);

                if (
                    lat * Math.pow(10, 15) < newSouth * Math.pow(10, 15)
                    || lat * Math.pow(10, 15) > newNorth * Math.pow(10, 15)
                    || lng * Math.pow(10, 15) > newEast * Math.pow(10, 15)
                    || lng * Math.pow(10, 15) < newWest * Math.pow(10, 15)
                ) {
                    this.changeMapCenter(lat, lng);
                    this.$emit('reachTheBoundary', {lat: lat, lng: lng});
                }
            },
            initRevvingUpFlags() {
                let index = 0;
                this.revvingUp.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagOne', item, index, 'revvingUp');
                });
            },
            removeRevvingUpFlags() {
                this.revvingUp.layers.forEach(function (value) {
                    value.remove();
                });
                this.revvingUp.layers.clear();
            },
            initDriverCallFlags() {
                let index = 0;
                this.driverCall.location.forEach((value) => {
                    index++;
                    let carId = value.carId;
                    let eventId = value.callId;
                    let id = 'driverCall' + carId + '-' + index;
                    let addressId = this.getAddressId('call', value.carId, index);
                    id = eventId;
                    if (id && !this.driverCall.layers.has(id)) {
                        let boxId = 'box' + id;
                        let flagBox = '<div class="ry-collision-box way-2-call-box" id="' + boxId + '"><span id="' + id + '" class="driver-call-icon via-microphone"></span></div>';
                        let icon = L.divIcon({
                            html: flagBox
                        });
                        let popUp = this.getPopUpStr('call', value, index);
                        let latlng = L.latLng(value.lat, value.lng);
                        // let marker = L.marker([value.lng, value.lat], {
                        let marker = L.marker(latlng, {
                            icon: icon,
                            iconSize: [20, 20],
                            iconAnchor: [20, 20],
                            zIndexOffset: 2000
                        });
                        if (!value.showPopup) {
                            marker.bindPopup(popUp)
                        }
                        marker.addTo(this.map);
                        let popupStatus = 0;
                        marker.on('popupclose', () => {
                            popupStatus = 1;
                            this.$emit('closeAlertBoxFromCollision')
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '20px';
                                document.getElementById(id).style.top = '';
                                document.getElementById(id).style.left = '9px';
                                document.getElementById(boxId).style.width = '';
                                document.getElementById(boxId).style.height = '';
                            }
                            this.removeIconTopClass(boxId);
                        });
                        marker.on('popupopen', () => {
                            let addressBox = document.getElementById(addressId);
                            if (addressBox) {
                                addressBox.innerText = '';
                                this.$Gps.getLocation(value.gps.latitude, value.gps.longitude)
                                    .then((location) => addressBox.innerText = location)
                                    .catch((location) => addressBox.innerText = location);
                            }
                            popupStatus = 1;
                            this.$emit('driverCallEvent', {
                                carId: carId,
                                eventId: eventId,
                                eventType: value.eventType,
                                callId: value.callId
                            });
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '30px'
                                document.getElementById(id).style.top = '6px';
                                document.getElementById(id).style.left = '11px';
                                document.getElementById(boxId).style.width = '43px';
                                document.getElementById(boxId).style.height = '43px';
                            }
                            this.addIconTopClass(boxId);
                        });
                        marker.on('click', () => {
                            if (popupStatus === 0) {
                                this.$emit('driverCallEvent', {
                                    carId: carId,
                                    eventId: eventId,
                                    eventType: value.eventType,
                                    callId: value.callId
                                });
                            }
                        });
                        this.driverCall.layers.set(id, marker);
                    }
                })
            },
            removeDriverCallFlags() {
                this.driverCall.layers.forEach(function (value) {
                    value.remove();
                });
                this.driverCall.layers.clear();
            },
            initHeadquarterCallFlags() {
                let index = 0;
                this.headquarterCall.location.forEach((value) => {
                    index++;
                    let carId = value.carId;
                    let eventId = value.callId;
                    let id = 'headquarterCall' + carId + '-' + index;
                    let addressId = this.getAddressId('call', value.carId, index);
                    id = eventId;
                    if (id && !this.headquarterCall.layers.has(id)) {
                        let boxId = 'box' + id;
                        let flagBox = '<div class="ry-collision-box way-2-call-box" id="' + boxId + '"><span id="' + id + '" class="driver-call-icon via-microphone"></span></div>';
                        let icon = L.divIcon({
                            html: flagBox
                        });
                        let popUp = this.getPopUpStr('call', value, index);
                        let latlng = L.latLng(value.lat, value.lng);
                        // let marker = L.marker([value.lng, value.lat], {
                        let marker = L.marker(latlng, {
                            icon: icon,
                            iconSize: [20, 20],
                            iconAnchor: [20, 20],
                            zIndexOffset: 2000
                        });
                        if (!value.showPopup) {
                            marker.bindPopup(popUp)
                        }
                        marker.addTo(this.map);
                        let popupStatus = 0;
                        marker.on('popupclose', () => {
                            popupStatus = 1;
                            this.$emit('closeAlertBoxFromCollision')
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '20px';
                                document.getElementById(id).style.top = '';
                                document.getElementById(id).style.left = '9px';
                                document.getElementById(boxId).style.width = '';
                                document.getElementById(boxId).style.height = '';
                            }
                            this.removeIconTopClass(boxId);
                        });
                        marker.on('popupopen', () => {
                            let addressBox = document.getElementById(addressId);
                            if (addressBox) {
                                addressBox.innerText = '';
                                this.$Gps.getLocation(value.gps.latitude, value.gps.longitude)
                                    .then((location) => addressBox.innerText = location)
                                    .catch((location) => addressBox.innerText = location);
                            }
                            popupStatus = 1;
                            this.$emit('headquarterCallEvent', {
                                carId: carId,
                                eventId: eventId,
                                eventType: value.eventType,
                                callId: value.callId
                            });
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '30px'
                                document.getElementById(id).style.top = '6px';
                                document.getElementById(id).style.left = '11px';
                                document.getElementById(boxId).style.width = '43px';
                                document.getElementById(boxId).style.height = '43px';
                            }
                            this.addIconTopClass(boxId);
                        });
                        marker.on('click', () => {
                            if (popupStatus === 0) {
                                this.$emit('headquarterCallEvent', {
                                    carId: carId,
                                    eventId: eventId,
                                    eventType: value.eventType,
                                    callId: value.callId
                                });
                            }
                        });
                        this.headquarterCall.layers.set(id, marker);
                    }
                })
            },
            removeHeadquarterCallFlags() {
                this.headquarterCall.layers.forEach(function (value) {
                    value.remove();
                });
                this.headquarterCall.layers.clear();
            },
            initHardBrakingFlags() {
                let index = 0;
                this.hardBraking.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagOne', item, index, 'hardBraking');
                });
            },
            removeHardBrakingFlags() {
                this.hardBraking.layers.forEach(function (value) {
                    value.remove();
                });
                this.hardBraking.layers.clear();
            },
            initHardCorneringFlags() {
                let index = 0;
                this.hardCornering.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagOne', item, index, 'hardCornering');
                });
            },
            removeHardCorneringFlags() {
                this.hardCornering.layers.forEach(function (value) {
                    value.remove();
                });
                this.hardCornering.layers.clear();
            },
            initLDWFlags() {
                let index = 0;
                this.LDW.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagTwo', item, index, 'LDW');
                });
            },
            removeLDWFlags() {
                this.LDW.layers.forEach(function (value) {
                    value.remove();
                });
                this.LDW.layers.clear();
            },
            initFCWFlags() {
                let index = 0;
                this.FCW.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagTwo', item, index, 'FCW');
                });
            },
            removeFCWFlags() {
                this.FCW.layers.forEach(function (value) {
                    value.remove();
                });
                this.FCW.layers.clear();
            },
            initDriverFatigueFlags() {
                let index = 0;
                this.driverFatigue.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagThree', item, index, 'driverFatigue');
                });
            },
            removeDriverFatigueFlags() {
                this.driverFatigue.layers.forEach(function (value) {
                    value.remove();
                });
                this.driverFatigue.layers.clear();
            },
            initDistractedDrivingFlags() {
                let index = 0;
                this.distractedDriving.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagThree', item, index, 'distractedDriving');
                });
            },
            removeDistractedDrivingFlags() {
                this.distractedDriving.layers.forEach(function (value) {
                    value.remove();
                });
                this.distractedDriving.layers.clear();
            },
            initSmokingFlags() {
                let index = 0;
                this.smoking.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagThree', item, index, 'smoking');
                });
            },
            removeSmokingFlags() {
                this.smoking.layers.forEach(function (value) {
                    value.remove();
                });
                this.smoking.layers.clear();
            },
            initPhoneUsageFlags() {
                let index = 0;
                this.phoneUsage.location.forEach((item) => {
                    index++;
                    let lat = item.lat;
                    let lng = item.lng;
                    let carId = item.carId;
                    this.makeFlag(lat, lng, carId, 'flagThree', item, index, 'phoneUsage');
                });
            },
            removePhoneUsageFlags() {
                this.phoneUsage.layers.forEach(function (value) {
                    value.remove();
                });
                this.phoneUsage.layers.clear();
            },
            makeFlag(lat, lng, carId, type, locationInfo, index, idTag) {
                if (typeof index === 'undefined') index = '-1';
                let id, flagBox, popUp;
                let addressId = this.getAddressId(type, locationInfo.carId, index);
                id = 'flagBoxId' + type + '-' + idTag + '-' + carId + '-' + index;
                if (type === 'flagOne') {
                    flagBox = "<div class='ry-flag-box' id='" + id + "'><span class='map-flag-type-one via-flag'></span></div>";
                    popUp = this.getPopUpStr('flagOne', locationInfo, index);
                } else if (type === 'flagTwo') {
                    flagBox = "<div class='ry-flag-box' id='" + id + "'><span class='map-flag-type-two via-flag'></span></div>";
                    popUp = this.getPopUpStr('flagTwo', locationInfo, index);
                } else if (type === 'flagThree') {
                    flagBox = "<div class='ry-flag-box' id='" + id + "'><span class='map-flag-type-three via-flag'></span></div>";
                    popUp = this.getPopUpStr('flagThree', locationInfo, index);
                } else {
                    id = 'ryFlagBoxDefault' + carId + '-' + index;
                    flagBox = "<div class='ry-flag-box' id='" + id + "'><span class='ry-flag-default via-flag'></span></div>";
                    popUp = this.getPopUpStr('Alert', locationInfo, index);
                }
                let icon = L.divIcon({
                    html: flagBox
                });
                let latlng = L.latLng(lat, lng);
                // let marker = L.marker([lng, lat], {
                let marker = L.marker(latlng, {
                    icon: icon,
                    iconSize: [20, 20],
                    iconAnchor: [20, 50],
                    zIndexOffset: 3000
                })
                    .bindPopup(popUp)
                    .addTo(this.map);
                marker.on('click', () => {
                });
                marker.on('popupclose', () => {
                    if (document.getElementById(id))
                        document.getElementById(id).style.fontSize = '20px';
                    this.removeIconTopClass(id);
                    //popup消失，index归0
                    this.currentImgIndex = 0;
                });
                marker.on('popupopen', () => {
                    let addressBox = document.getElementById(addressId);
                    if (addressBox) {
                        addressBox.innerText = '';
                        this.$Gps.getLocation(locationInfo.gps.latitude, locationInfo.gps.longitude)
                            .then((location) => addressBox.innerText = location)
                            .catch((location) => addressBox.innerText = location);
                    }
                    if (document.getElementById(id))
                        document.getElementById(id).style.fontSize = '30px'
                    this.addIconTopClass(id);
                    //popup显示，index归0
                    this.currentImgIndex = 0;
                });
                if (type === 'flagOne') {
                    if (idTag === 'revvingUp') {
                        this.revvingUp.layers.set(id, marker);
                    } else if (idTag === 'hardBraking') {
                        this.hardBraking.layers.set(id, marker);
                    } else if (idTag === 'hardCornering') {
                        this.hardCornering.layers.set(id, marker);
                    }
                } else if (type === 'flagTwo') {
                    if (idTag === 'LDW') {
                        this.LDW.layers.set(id, marker);
                    } else if (idTag === 'FCW') {
                        this.FCW.layers.set(id, marker);
                    }
                } else if (type === 'flagThree') {
                    if (idTag === 'driverFatigue') {
                        this.driverFatigue.layers.set(id, marker);
                    } else if (idTag === 'distractedDriving') {
                        this.distractedDriving.layers.set(id, marker);
                    } else if (idTag === 'smoking') {
                        this.smoking.layers.set(id, marker);
                    } else if (idTag === 'phoneUsage') {
                        this.phoneUsage.layers.set(id, marker);
                    }
                }
            },
            getAddressId(type, id, index) {
                if (!(index >= 0)) index = 'no';
                return type + 'PopupAddr' + id + 'Index' + index;
            },
            getPopUpStr(type, object, index) {
                let title, className;
                title = object.name;
                let addressId = this.getAddressId(type, object.carId, index);
                if (type === 'flagOne') {
                    className = 'map-flag-type-one';
                } else if (type === 'flagTwo') {
                    className = 'map-flag-type-two';
                } else if (type === 'flagThree') {
                    className = 'map-flag-type-three';
                } else if (type === 'collision' || type === 'DriverAlert' || type === 'ParkingAlert' || type === 'UnpermittedDriver') {
                    className = 'ry-flag-Collision';
                } else if (type === 'call') {
                    className = 'pub-map-call'
                } else {
                    title = 'Alert';
                    className = 'ry-flag-default';
                }
                if (object.count)
                    title += '(' + object.count + ')';
                let notShowClass = object.speed === undefined ? 'ry-not-sow' : '';
                this.alertImageArray = object.picture ? object.picture : [];
                this.imgsArrMap.set(object.timestamp + '', Object.assign([], this.alertImageArray));
                let hasAlertImg = this.alertImageArray.length > 0;
                let alertImg = Object.assign([], this.alertImageArray);
                let alertData = this.alertImageArray;
                let alertImgClass = hasAlertImg ? 'showAlertImg' : 'dismissAlertImg';
                // let alertImgClass = 'dismissAlertImg';
                let bottomFlag = "1/" + this.imgsArrMap.get(object.timestamp + '').length;
                return '<div class="ry-pup-up-box">' +
                    '<div class="ry-pup-up-top"><span class="via-warning_bak map-icon ' + className + ' ' + (object.iconName || 'map-icon-no') + '"></span>' + title + '</div>' +
                    '<div class="ry-pup-up-middle">' +
                    '<span class="via-hours map-icon"></span><span class="ry-time">' + object.time + '</span> ' +
                    '<span class="ry-speed ' + notShowClass + '"><span class="via-speedometer map-icon"></span> ' + object.speed + ' ' + object.speedUnit + '</span>' +
                    '</div>' +
                    '<div class="ry-pup-up-bottom"><span class="via-map-marker map-icon"></span><span id="' + addressId + '">' + object.address + '</span></div>' +
                    '<div class="' + alertImgClass + '">' +
                    `<img style="width: 330px;height:186px;cursor: pointer" src="${alertImg[0]}" onclick="onClickAlertImg('${object.timestamp}')"
                          id="alertImgId" onerror="onImgError()" onmouseover="onMouseOver('${object.timestamp}')" onmouseleave="onMouseLeave()">` +
                    `<img class="arrow_display" src="${require('../../assets/image/left_arrow.png')}" onclick="onClickLeftArrow('${object.timestamp}')" id="leftArrow" onmouseover="onMouseOver()" onmouseleave="onMouseLeave()">` +
                    `<img class="arrow_display" src="${require('../../assets/image/right_arrow.png')}" onclick="onClickRightArrow('${object.timestamp}')" id="rightArrow" onmouseover="onMouseOver()" onmouseleave="onMouseLeave()">` +
                    `<div class="alert-img-bottom" id="alertImgBottom">` + bottomFlag + `</div>` +
                    '</div>' +
                    '</div>';
//
            },
            initHistoryPath() {
                let location = [];
                let index = 0;
                this.historyPath.location.forEach((value) => {
                    if (value && value.lat && value.lng) {
                        location[index] = [value.lat, value.lng];
                        index++;
                    }
                });
                if (location.length === 0) return;
                let polyline = L.polyline(location, {
                    color: '#a2a2a2',
                    weight: 6,
                    lineCap: 'round',
                    lineJoin: 'round',
                }).addTo(this.map);
                if (this.historyPath.fitBounds)
                    this.map.fitBounds(polyline.getBounds());
                this.historyPath.layer = polyline;
            },
            removeHistoryPath() {
                if (this.historyPath.layer) {
                    this.historyPath.layer.remove();
                }
                this.historyPath.layer = null;
            },
            initBoundaryPath() {
                let location = [];
                let index = 0;
                this.maxBoundaryPath.location.forEach((value) => {
                    if (value && value.lat && value.lng) {
                        location[index] = [value.lat, value.lng];
                        index++;
                    }
                });
                if (location.length === 0) return;
                let polyline = L.polyline(location, {
                    color: '#ffffff00',
                    weight: 0,
                    lineCap: 'round',
                    lineJoin: 'round',
                }).addTo(this.map);
                this.map.fitBounds(polyline.getBounds());
                this.maxBoundaryPath.layer = polyline;
            },
            removeBoundaryPath() {
                if (this.maxBoundaryPath.layer) {
                    this.maxBoundaryPath.layer.remove();
                }
                this.maxBoundaryPath.layer = null;
            },
            initStartLocation() {
                if (
                    typeof this.startLocation.location.lng === 'undefined'
                    || typeof this.startLocation.location.lat === 'undefined'
                ) {
                    return;
                }
                let language = this.$store.getters['i18n/localLanguage'];
                let temp = '';
                if (language === 'en-US') {
                    temp = require('@/assets/image/start_e.svg');
                }else if (language === 'zh-CN') {
                    temp = require('@/assets/image/start_cn.svg');
                }else {
                    temp = require('@/assets/image/start_tw.svg');
                }
                let icon = L.divIcon({
                    html: `<div class="ry-start-location">`+
                            `<img src="${temp}" width="40px"/>`+
                          `</div>`
                });
                let latlng = L.latLng(this.startLocation.location.lat, this.startLocation.location.lng);
                // this.startLocation.layer = L.marker([this.startLocation.location.lng, this.startLocation.location.lat], {
                this.startLocation.layer = L.marker(latlng, {
                    icon: icon,
                    iconSize: [40, 40],
                    iconAnchor: [40, 40],
                    zIndexOffset: 1001
                }).addTo(this.map);
            },
            removeStartLocation() {
                if (this.startLocation.layer) {
                    this.startLocation.layer.remove();
                }
                this.startLocation.layer = null;
            },
            initEndLocation() {
                if (
                    typeof this.endLocation.location.lng === 'undefined'
                    || typeof this.endLocation.location.lat === 'undefined'
                ) {
                    return;
                }
                let language = this.$store.getters['i18n/localLanguage'];
                let temp = '';
                if (language === 'en-US') {
                    temp = require('@/assets/image/end_e.svg');
                }else if (language === 'zh-CN') {
                    temp = require('@/assets/image/end_cn.svg');
                }else {
                    temp = require('@/assets/image/end_tw.svg');
                }
                let icon = L.divIcon({
                    html: `<div class="ry-end-location">`+
                            `<img src="${temp}" width="32px" />`+
                          `</div>`
                });
                let latlng = L.latLng(this.endLocation.location.lat, this.endLocation.location.lng);
                // this.endLocation.layer = L.marker([this.endLocation.location.lng, this.endLocation.location.lat], {
                this.endLocation.layer = L.marker(latlng, {
                    icon: icon,
                    iconSize: [20, 20],
                    iconAnchor: [20, 50],
                    zIndexOffset: 1500
                }).addTo(this.map);
            },
            removeEndLocation() {
                if (this.endLocation.layer) {
                    this.endLocation.layer.remove();
                }
                this.endLocation.layer = null;
            },
            initCollisionsCars() {
                let index = 0;
                this.collisionsCars.location.forEach((value) => {
                    index++;
                    let carId = value.carId;
                    let eventId = value.eventId;
                    let id = 'carCollision' + carId + '-' + index;
                    let addressId = this.getAddressId('collision', value.carId, index);
                    id = eventId;
                    if (id && !this.collisionsCars.layers.has(id)) {
                        let boxId = 'box' + id;
                        let flagBox = '<div class="ry-collision-box" id="' + boxId + '"><span id="' + id + '" class="car-crash-icon via-car-crash"></span></div>';
                        let icon = L.divIcon({
                            html: flagBox
                        });
                        let popUp = this.getPopUpStr('collision', value, index);
                        let latlng = L.latLng(value.lat, value.lng);
                        // let marker = L.marker([value.lng, value.lat], {
                        let marker = L.marker(latlng, {
                            icon: icon,
                            iconSize: [20, 20],
                            iconAnchor: [20, 20],
                            zIndexOffset: 2000
                        });
                        if (!value.showPopup) {
                            marker.bindPopup(popUp)
                        }
                        marker.addTo(this.map);
                        let popupStatus = 0;
                        marker.on('popupclose', () => {
                            popupStatus = 1;
                            this.$emit('closeAlertBoxFromCollision')
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '20px';
                                document.getElementById(id).style.top = '';
                                document.getElementById(boxId).style.width = '';
                                document.getElementById(boxId).style.height = '';
                            }
                            this.removeIconTopClass(boxId);
                        });
                        marker.on('popupopen', () => {
                            let addressBox = document.getElementById(addressId);
                            if (addressBox) {
                                addressBox.innerText = '';
                                this.$Gps.getLocation(value.gps.latitude, value.gps.longitude)
                                    .then((location) => addressBox.innerText = location)
                                    .catch((location) => addressBox.innerText = location);
                            }
                            popupStatus = 1;
                            this.$emit('collisionEvent', {carId: carId, eventId: eventId, eventType: value.eventType});
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '30px'
                                document.getElementById(id).style.top = '6px';
                                document.getElementById(boxId).style.width = '43px';
                                document.getElementById(boxId).style.height = '43px';
                            }
                            this.addIconTopClass(boxId);
                        });
                        marker.on('click', () => {
                            if (popupStatus === 0) {
                                this.$emit('collisionEvent', {
                                    carId: carId,
                                    eventId: eventId,
                                    eventType: value.eventType
                                });
                            }
                        });
                        this.collisionsCars.layers.set(id, marker);
                    }
                })
            },
            removeCollisionsCars() {
                this.collisionsCars.layers.forEach(function (value) {
                    value.remove();
                });
                this.collisionsCars.layers.clear();
            },
            initParkingAlertEvent() {
                let index = 0;
                this.parkingAlertEvent.location.forEach((value) => {
                    index++;
                    let carId = value.carId;
                    let eventId = value.eventId;
                    let id = 'ParkingAlert' + carId + '-' + index;
                    let addressId = this.getAddressId('ParkingAlert', value.carId, index);
                    id = eventId;
                    if (id && !this.parkingAlertEvent.layers.has(id)) {
                        let boxId = 'box' + id;
                        let flagBox = '<div class="ry-collision-box" id="' + boxId + '"><span id="' + id + '" class="car-crash-icon event-parking-alert via-Parking-alert"></span></div>';
                        let icon = L.divIcon({
                            html: flagBox
                        });
                        let popUp = this.getPopUpStr('ParkingAlert', value, index);
                        let latlng = L.latLng(value.lat, value.lng);
                        // let marker = L.marker([value.lng, value.lat], {
                        let marker = L.marker(latlng, {
                            icon: icon,
                            iconSize: [20, 20],
                            iconAnchor: [20, 20],
                            zIndexOffset: 2000
                        });
                        if (!value.showPopup) {
                            marker.bindPopup(popUp)
                        }
                        marker.addTo(this.map);
                        let popupStatus = 0;
                        marker.on('popupclose', () => {
                            popupStatus = 1;
                            this.$emit('closeAlertBoxFromParkingAlert')
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '20px';
                                document.getElementById(id).style.top = '';
                                document.getElementById(boxId).style.width = '';
                                document.getElementById(boxId).style.height = '';
                            }
                            this.removeIconTopClass(boxId);
                        });
                        marker.on('popupopen', () => {
                            let addressBox = document.getElementById(addressId);
                            if (addressBox) {
                                addressBox.innerText = '';
                                this.$Gps.getLocation(value.gps.latitude, value.gps.longitude)
                                    .then((location) => addressBox.innerText = location)
                                    .catch((location) => addressBox.innerText = location);
                            }
                            popupStatus = 1;
                            this.$emit('parkingAlertEvent', {
                                carId: carId,
                                eventId: eventId,
                                eventType: value.eventType
                            });
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '30px'
                                document.getElementById(id).style.top = '6px';
                                document.getElementById(boxId).style.width = '43px';
                                document.getElementById(boxId).style.height = '43px';
                            }
                            this.addIconTopClass(boxId);
                        });
                        marker.on('click', () => {
                            if (popupStatus === 0) {
                                this.$emit('parkingAlertEvent', {
                                    carId: carId,
                                    eventId: eventId,
                                    eventType: value.eventType
                                });
                            }
                        });
                        this.parkingAlertEvent.layers.set(id, marker);
                    }
                })
            },
            removeParkingAlertEvent() {
                this.parkingAlertEvent.layers.forEach(function (value) {
                    value.remove();
                });
                this.parkingAlertEvent.layers.clear();
            },
            initDriverAlertEvent() {
                let index = 0;
                this.driverAlertEvent.location.forEach((value) => {
                    index++;
                    let carId = value.carId;
                    let eventId = value.eventId;
                    let id = 'DriverAlert' + carId + '-' + index;
                    let addressId = this.getAddressId('DriverAlert', value.carId, index);
                    id = eventId;
                    if (id && !this.driverAlertEvent.layers.has(id)) {
                        let boxId = 'box' + id;
                        let flagBox = '<div class="ry-collision-box" id="' + boxId + '"><span id="' + id + '" class="car-crash-icon event-driver-alert via-Driver-alert"></span></div>';
                        let icon = L.divIcon({
                            html: flagBox
                        });
                        let popUp = this.getPopUpStr('DriverAlert', value, index);
                        let latlng = L.latLng(value.lat, value.lng);
                        // let marker = L.marker([value.lng, value.lat], {
                        let marker = L.marker(latlng, {
                            icon: icon,
                            iconSize: [20, 20],
                            iconAnchor: [20, 20],
                            zIndexOffset: 2000
                        });
                        if (!value.showPopup) {
                            marker.bindPopup(popUp)
                        }
                        marker.addTo(this.map);
                        let popupStatus = 0;
                        marker.on('popupclose', () => {
                            popupStatus = 1;
                            this.$emit('closeAlertBoxFromDriverAlert')
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '20px';
                                document.getElementById(id).style.top = '';
                                document.getElementById(boxId).style.width = '';
                                document.getElementById(boxId).style.height = '';
                            }
                            this.removeIconTopClass(boxId);
                        });
                        marker.on('popupopen', () => {
                            let addressBox = document.getElementById(addressId);
                            if (addressBox) {
                                addressBox.innerText = '';
                                this.$Gps.getLocation(value.gps.latitude, value.gps.longitude)
                                    .then((location) => addressBox.innerText = location)
                                    .catch((location) => addressBox.innerText = location);
                            }
                            popupStatus = 1;
                            this.$emit('driverAlertEvent', {
                                carId: carId,
                                eventId: eventId,
                                eventType: value.eventType
                            });
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '30px'
                                document.getElementById(id).style.top = '6px';
                                document.getElementById(boxId).style.width = '43px';
                                document.getElementById(boxId).style.height = '43px';
                            }
                            this.addIconTopClass(boxId);
                        });
                        marker.on('click', () => {
                            if (popupStatus === 0) {
                                this.$emit('driverAlertEvent', {
                                    carId: carId,
                                    eventId: eventId,
                                    eventType: value.eventType
                                });
                            }
                        });
                        this.driverAlertEvent.layers.set(id, marker);
                    }
                })
            },
            removeDriverAlertEvent() {
                this.driverAlertEvent.layers.forEach(function (value) {
                    value.remove();
                });
                this.driverAlertEvent.layers.clear();
            },
            initUnpermittedDriverEvent() {
                let index = 0;
                this.unpermittedDriverEvent.location.forEach((value) => {
                    index++;
                    let carId = value.carId;
                    let eventId = value.eventId;
                    let id = 'UnpermittedDriver' + carId + '-' + index;
                    let addressId = this.getAddressId('UnpermittedDriver', value.carId, index);
                    id = eventId;
                    if (id && !this.unpermittedDriverEvent.layers.has(id)) {
                        let boxId = 'box' + id;
                        let flagBox = '<div class="ry-collision-box" id="' + boxId + '"><span id="' + id + '" class="car-crash-icon event-unpermitted via-Unpermitted"></span></div>';
                        let icon = L.divIcon({
                            html: flagBox
                        });
                        let popUp = this.getPopUpStr('UnpermittedDriver', value, index);
                        let latlng = L.latLng(value.lat, value.lng);
                        // let marker = L.marker([value.lng, value.lat], {
                        let marker = L.marker(latlng, {
                            icon: icon,
                            iconSize: [20, 20],
                            iconAnchor: [20, 20],
                            zIndexOffset: 2000
                        });
                        if (!value.showPopup) {
                            marker.bindPopup(popUp)
                        }
                        marker.addTo(this.map);
                        let popupStatus = 0;
                        marker.on('popupclose', () => {
                            popupStatus = 1;
                            this.$emit('closeAlertBoxFromUnpermittedDriver')
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '20px';
                                document.getElementById(id).style.top = '';
                                document.getElementById(boxId).style.width = '';
                                document.getElementById(boxId).style.height = '';
                            }
                            this.removeIconTopClass(boxId);
                        });
                        marker.on('popupopen', () => {
                            let addressBox = document.getElementById(addressId);
                            if (addressBox) {
                                addressBox.innerText = '';
                                this.$Gps.getLocation(value.gps.latitude, value.gps.longitude)
                                    .then((location) => addressBox.innerText = location)
                                    .catch((location) => addressBox.innerText = location);
                            }
                            popupStatus = 1;
                            this.$emit('unpermittedDriverEvent', {
                                carId: carId,
                                eventId: eventId,
                                eventType: value.eventType
                            });
                            if (document.getElementById(id)) {
                                document.getElementById(id).style.fontSize = '30px';
                                document.getElementById(id).style.top = '6px';
                                document.getElementById(boxId).style.width = '43px';
                                document.getElementById(boxId).style.height = '43px';
                            }
                            this.addIconTopClass(boxId);
                        });
                        marker.on('click', () => {
                            if (popupStatus === 0) {
                                this.$emit('unpermittedDriverEvent', {
                                    carId: carId,
                                    eventId: eventId,
                                    eventType: value.eventType
                                });
                            }
                        });
                        this.unpermittedDriverEvent.layers.set(id, marker);
                    }
                })
            },
            removeUnpermittedDriverEvent() {
                this.unpermittedDriverEvent.layers.forEach(function (value) {
                    value.remove();
                });
                this.unpermittedDriverEvent.layers.clear();
            },
            initTargetCollisionCar() {
                if (!this.targetCollision) return;
                let markerLayer;
                let domId = 'box' + this.targetCollision.eventId;
                switch (this.targetCollision.eventType) {
                    case A_UNPERMITTED_DRIVER_VALUE:
                        markerLayer = this.unpermittedDriverEvent.layers.get(this.targetCollision.eventId);
                        break;
                    case A_PARKING_MODE_COLLISION_VALUE:
                        markerLayer = this.parkingAlertEvent.layers.get(this.targetCollision.eventId);
                        break;
                    case A_DRIVER_ALERT_VALUE:
                        markerLayer = this.driverAlertEvent.layers.get(this.targetCollision.eventId);
                        break;
                    case A_COLLISION_VALUE:
                        markerLayer = this.collisionsCars.layers.get(this.targetCollision.eventId);
                        break;
                    default:
                        markerLayer = this.collisionsCars.layers.get(this.targetCollision.eventId);
                        break;
                }
                if (markerLayer) {
                    this.targetCollisionCar.eventId = this.targetCollision.eventId;
                    this.targetCollisionCar.type = this.targetCollision.eventType;
                    markerLayer.openPopup();
                    if (this.targetCollision.center) {
                        this.changeMapCenter(this.targetCollision.center.lat, this.targetCollision.center.lng);
                    }
                    this.addIconTopClass(domId);
                }
            },
            removeTargetCollisionCar() {
                if (this.targetCollisionCar.type === null || this.targetCollisionCar.eventId === null) return;
                let markerLayer;
                let domId = 'box' + this.targetCollisionCar.eventId;
                switch (this.targetCollisionCar.type) {
                    case A_UNPERMITTED_DRIVER_VALUE:
                        markerLayer = this.unpermittedDriverEvent.layers.get(this.targetCollisionCar.eventId);
                        break;
                    case A_PARKING_MODE_COLLISION_VALUE:
                        markerLayer = this.parkingAlertEvent.layers.get(this.targetCollisionCar.eventId);
                        break;
                    case A_DRIVER_ALERT_VALUE:
                        markerLayer = this.driverAlertEvent.layers.get(this.targetCollisionCar.eventId);
                        break;
                    case A_COLLISION_VALUE:
                        markerLayer = this.collisionsCars.layers.get(this.targetCollisionCar.eventId);
                        break;
                    default:
                        markerLayer = this.collisionsCars.layers.get(this.targetCollisionCar.eventId);
                        break;
                }
                if (markerLayer) {
                    markerLayer.closePopup();
                    this.removeIconTopClass(domId);
                }
                this.targetCollisionCar.eventId = null;
                this.targetCollisionCar.type = null;
            },
            initCarsLocation() {
                this.carsLocation.location.forEach((value, index) => {
                    let carId = value.carId;
                    let id = 'carLocation' + carId;
                    let styleStr = 'style="';
                    let carColor = value.color ? 'color: ' + value.color + ';' : '';
                    let fleetColor = 'border-color:' + value.fleetColor + ';';
                    styleStr += carColor;
                    styleStr += fleetColor;
                    styleStr += '"';
                    let flagBox = '<div class="ry-car-box" ' + styleStr + '><span id="' + id + '" class="map-cars via-car"></span></div>';
                    let icon = L.divIcon({
                        html: flagBox
                    });
                    let latlng = L.latLng(value.lat, value.lng);
                    // let marker = L.marker([value.lng, value.lat], {
                    let marker = L.marker(latlng, {
                        icon: icon,
                        iconSize: [20, 20],
                        iconAnchor: [20, 20],
                    }).addTo(this.map);
                    marker.on('click', () => {
                        this.$emit('car', carId, index);
                    });
                    this.carsLocation.layers.set(carId, marker);
                })
            },
            removeCarsLocation() {
                this.carsLocation.layers.forEach(function (value) {
                    value.remove();
                });
                this.carsLocation.layers.clear();
            },
            initTargetCarLocation() {
                if (typeof this.targetCarLocation.location.lng === 'undefined'
                    || typeof this.targetCarLocation.location.lat === 'undefined'
                ) {
                    return;
                }
                let carId = this.targetCarLocation.location.carId || 0;
                let id = 'targetCarLocation' + carId;
                let carColor = this.targetCarLocation.location.color
                    ? 'style="color: ' + this.targetCarLocation.location.color + ';"' : '';
                let flagBox = '<div class="ry-target-car-box" ' + carColor + '><span id="' + id + '" class="via-map-marker icon-target-car"></span></div>';
                let icon = L.divIcon({
                    html: flagBox
                });
                let latlng = L.latLng(this.targetCarLocation.location.lat, this.targetCarLocation.location.lng);
                // let marker = L.marker([this.targetCarLocation.location.lng, this.targetCarLocation.location.lat], {
                let marker = L.marker(latlng, {
                    icon: icon,
                    iconSize: [20, 20],
                    iconAnchor: [20, 20],
                }).addTo(this.map);
                this.targetCarLocation.layer = marker;
                this.checkValidBound(this.targetCarLocation.location.lng, this.targetCarLocation.location.lat, 0.15, 0.25, 0.15, 0.25);
            },
            removeTargetCarLocation() {
                if (this.targetCarLocation.layer !== null) {
                    this.targetCarLocation.layer.remove();
                }
                this.targetCarLocation.layer = null;
            },
            checkFirstCarBound() {
                if (
                    typeof this.cars[0] !== 'undefined'
                    && typeof this.cars[0].lng !== 'undefined'
                    && typeof this.cars[0].lat !== 'undefined'
                    && typeof this.targetCar.lng === 'undefined'
                ) {
                    this.checkValidBound(this.cars[0].lng, this.cars[0].lat, 0.15, 0.25, 0.15, 0.25);
                }
            },
            initAutoBoundaryPath() {
                let location = [];
                let index = 0;
                this.autoBoundaryPath.location.forEach((value) => {
                    if (value && value.lat && value.lng) {
                        location[index] = [value.lat, value.lng];
                        index++;
                    }
                });
                if (location.length === 0) return;
                let polyline = L.polyline(location, {
                    color: '#a2a2a2',
                    weight: 0,
                    lineCap: 'round',
                    lineJoin: 'round',
                }).addTo(this.map);
                this.map.fitBounds(polyline.getBounds());
                this.autoBoundaryPath.layer = polyline;
            },
            removeAutoBoundaryPath() {
                if (this.autoBoundaryPath.layer) {
                    this.autoBoundaryPath.layer.remove();
                }
                this.autoBoundaryPath.layer = null;
            },
            addIconTopClass(domId) {
                let targetDom = document.getElementById(domId);
                if (targetDom && targetDom.parentNode)
                    targetDom.parentNode.classList.add('ry-icon-top');
            },
            removeIconTopClass(domId) {
                let targetDom = document.getElementById(domId);
                if (targetDom && targetDom.parentNode)
                    targetDom.parentNode.classList.remove('ry-icon-top');
            },
            // 暂时保存
            // test(e) {
            //     this.checkValidBound(41.83, -87.62, 0.15, 0.25, 0.15, 0.25);
            // },
            // testM(e) {
            //     console.log('this is test()')
            //     console.log('this.map.getBounds(): ', this.map.getBounds())
            //     console.log('this.map.getBounds().getCenter()', this.map.getBounds().getCenter())
            //     console.log('this.map.getPixelBounds(); ', this.map.getPixelBounds())
            //     console.log('this.map.getPixelBounds().getSize():', this.map.getPixelBounds().getSize())
            //     console.log('e:', e)
            // },
            initBehindPath() {
                let polyline = L.polyline(this.currentBehindPath, {
                    color: 'grey',
                    weight: 5,
                    lineCap: 'round',
                    lineJoin: 'round',
                }).addTo(this.map);
                this.map.fitBounds(polyline.getBounds());
                this.behindPathLayer = polyline;
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .ry-com-leaflet-map
        width 100%
        height 100%
        background-color $color_white

        >>> .ry-icon-top
            z-index 5000 !important

        .ry-map
            width 100%
            height 100%

        >>> .leaflet-div-icon
            border 0
            background-color transparent

        >>> .ry-flag-box
            position relative
            top -15px
            left -3px
            background-color transparent
            font-size 20px

        >>> .ry-flag-box .via-flag:hover,
        >>> .way-2-call-box .via-microphone:hover
            font-size 30px !important
            -webkit-text-stroke: 1px #ffffff
            cursor pointer

        >>> .way-2-call-box .via-microphone:hover
            text-shadow 0px 0px 30px #2eaf9e

        >>> .ry-flag-box .map-flag-type-one:hover
            text-shadow: 0px 0px 30px #00c6ff

        >>> .ry-flag-box .map-flag-type-two:hover
            text-shadow: 0px 0px 30px #ff9c00

        >>> .ry-flag-box .map-flag-type-three:hover
            text-shadow: 0px 0px 30px #e354f0

        >>> .ry-collision-box:hover
            cursor pointer

        >>> .ry-collision-box:hover .car-crash-icon
            font-size 30px !important
            -webkit-text-stroke: 1px #ffffff
            text-shadow: 0px 0px 30px #ff0000

        >>> .map-flag-type-one
            color #00c6ff

        >>> .map-flag-type-two
            color #ff9c00

        >>> .map-flag-type-three
            color #e354f0

        >>> .ry-flag-default
            color #000000

        >>> .ry-flag-Collision
            color #f00

        >>> .pub-map-call
            color #2eaf9e

        >>> .ry-collision-box
            color #ff0000
            font-size 20px
            position relative
            top -10px
            left -12px
            width 33px
            height 33px
            background-color rgba(255, 255, 255, .8)
            border-radius 50%

            .driver-call-icon
                color #2eaf9e
                position: relative;
                left 9px
                top 3px

            .car-crash-icon
                position relative
                left 3px
                top 3px

            .event-driver-alert
                left 8px

            .event-parking-alert
                left 9px

            .event-unpermitted
                left 7px
                top 2px

        >>> .ry-car-box
            position relative
            left -7px
            top 2px
            border-width 2px
            border-style solid
            color #000000
            font-size 25px
            width 35px
            height 35px
            background-color rgba(255, 255, 255, .9)
            border-radius 100%

        >>> .map-cars
            position relative
            left 3px
            top 1px

        >>> .ry-target-car-box
            position relative
            top -28px
            left 4px
            color #000000
            font-size 25px

            .icon-target-car
                color $color_blue_first

        >>> .leaflet-popup
            margin-bottom 40px

        >>> .ry-pup-up-box
            cursor default
            font-size 14px
        >>> .showAlertImg
            width 330px
            position relative
        >>> .left-arrow
            position absolute
            left 10px
            top 50%
            margin-top -16px
            cursor pointer

        >>> .right-arrow
            position absolute
            right 10px
            top 50%
            margin-top -16px
            cursor pointer

        >>> .arrow_display
            display none
        >>> .right-arrow:hover
            background-image url("../../assets/image/right_arrow_hover.png")

        >>> .left-arrow:hover
            background-image url("../../assets/image/left_arrow_hover.png")

        >>> .alert-img-bottom
            position absolute
            left 50%
            top 100%
            margin-left -24px
            margin-top -25px
            display inline-block
            background-color rgba(0, 0, 0, 0.3)
            width 48px
            height 16px
            font-size 14px
            text-align center
            border-radius 3px
            color white
            font-weight lighter

        >>> .dismissAlertImg
            display none

        >>> .ry-pup-up-top
            font-weight 600
            margin-bottom 16px

        >>> .leaflet-popup-content
            width 330px !important

        >>> .ry-pup-up-middle
            margin-bottom 16px
            display block

            .ry-time, .ry-speed
                white-space nowrap

            .ry-time
                display inline-block
                width calc(100% - 160px)

            .ry-speed
                display inline-block
                width 130px !important
                text-align right

        >>> .ry-pup-up-bottom
            word-break keep-all
            word-wrap break-word

        >>> .ry-start-location, >>> .ry-end-location
            font-size 40px
            color $color_black_first

        >>> .ry-start-location img, >>> .ry-end-location img
            cursor grab

        >>> .ry-start-location
            position relative
            left -20px
            top -20px

        >>> .ry-end-location
            position relative
            left -20px
            top -20px

            img
                position relative
                left 4px
                top -4px

        >>> .map-icon
            text-align left
            width 20px
            display inline-block !important

        >>> .map-icon-no
            width 0px

        >>> .ry-not-sow
            display none !important

        >>> .leaflet-popup-content-wrapper
            border-radius 5px

        >>> .leaflet-popup-content
            margin 18px 12px

    .leaflet-control-scale .leaflet-control-scale-line
        text-align center
        color red
</style>

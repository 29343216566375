<template>
    <com-layout class="monitor-main-simple-monitor">
        <template v-slot:map>
            <com-leaflet-map
                    type="google"
                    :zoom="mapOptions.zoom"
                    :zoom-center="mapOptions.zoomCenter"
                    :center="mapOptions.center"
                    :cars="mapOptions.cars"
                    @car="handleSelectCarFromMap"
                    @reachTheBoundary="handleCarReachTheBoundary"
                    :target-car="mapOptions.targetCar"
                    :auto-boundary="mapOptions.boundary"/>
        </template>

        <template v-slot:left>
            <com-left-tree
                    :id="mapOptions.currentSelectCarId"
                    :ws-all-data="socketOption.allData"
                    :ws-update-data="socketOption.updateData"
                    :ws-collision-data="socketOption.collisionData"
                    :ws-two-way-call-data="socketOption.twoWayCallData"
                    :ws-two-way-call-answer-data="socketOption.twoWayCallAnswerData"
                    @node-click="handleNodeClick"
                    @currentVehicleChange="handleCurrentVehicleChange"
                    @callRequestStateChange="handleCallRequestStateChange"/>
        </template>


        <div>
            <com-alert-message-v2
                    v-if="messageOption.isShow"
                    :on-road="fleetOption.isOnRoad"
                    :data="alertMessageData"
                    :target-car-location="mapOptions.targetCarLocation"
                    :real-time-data="socketOption.currentCarData"
                    :collision-data="collisionOption.data"
                    :show-collision="collisionOption.isShow"
                    :show-video="messageOption.showVideo"
                    :show-voice="messageOption.showVoice"
                    :is-show-mic-icon-animation="messageOption.webRTC.isBlink"
                    @update-ws-kvs-no-time="handleWsKvsNoTime"
                    @close="handleCloseAlertMessage"
            />
        </div>
    </com-layout>
</template>

<script>
    // import ComBottomTable from '../../components/monitor/ComBottomTable';
    import ComLeafletMap from '../../components/monitor/ComLeafletMap';
    import ComAlertMessageV2 from '../../components/monitor/ComAlertMessageV2';
    import ComLayout from '../../components/monitor/ComLayout';
    import bus from "../../utils/bus";
    import ComLeftTree from "../../components/monitor/ComLeftTree"
    import websocketConfig from "../../assets/js/websocketConfig";
    import CoordinateCovernt from "@/assets/js/CoordinateConvertUtil";
    import device from "../../store/module/device";

    export default {
        name: "MapTmp2",
        components: {
            // ComBottomTable,
            ComAlertMessageV2,
            ComLayout,
            ComLeafletMap,
            ComLeftTree
        },
        created() {
            let companyLocation = localStorage.getItem("companyLocation") ? JSON.parse(localStorage.getItem("companyLocation")) : null;
            let newGps = CoordinateCovernt.gcj_encrypt(companyLocation ? (companyLocation.lat ? companyLocation.lat : 24.999) : 24.999, companyLocation ? (companyLocation.lng ? companyLocation.lng : 121.59154) : 121.59154);
            this.mapOptions.center = {
                lng: newGps.Longitude,
                lat: newGps.Latitude
            }
        },
        mounted() {
            this.$nextTick(() => {
                document.getElementById('mainContent').style.height = '100%';
                document.getElementById('mainContent').style.paddingBottom = '0px';
                document.getElementById('contentBox').style.paddingBottom = '0px';
                this.getDriverInfo();
                this.getVehicleList();
            });
            if (this.getToken()) {
                this.wsOpen();
                // 通过web请求延长token的有效期
                this.intervalTimer.touch = setInterval(() => {
                    this.$serverApi.auth.touch();
                    console.log('Live Tracking touch:', new Date().toString(), '5 * 60 * 1000');
                }, 5 * 60 * 1000);
                // 通过ping来延迟websocket的连接时长
                this.intervalTimer.ping = setInterval(() => {
                    let msg = {"action": "ping"};
                    msg = JSON.stringify(msg);
                    if (this.socketOption.socket !== null) {
                        this.socketOption.socket.send(msg);
                        console.log('Live Tracking ping:', new Date().toString(), '5 * 60 * 1000');
                    }
                }, 5 * 60 * 1000);
                // 解决aws每隔2小时强制掉线的问题
                this.intervalTimer.awsReconnect = setInterval(() => {
                    if (this.socketOption.socket) {
                        this.wsClose();
                        this.getToken();
                        this.wsOpen();
                        console.log('Live Tracking socket reconnect:', new Date().toString(), '110 * 60 * 1000');
                    }
                }, 110 * 60 * 1000);
            } else {
                console.error('Live Tracking cannot get token', new Date().toString());
                this.$router.push('/login')
            }
        },
        beforeDestroy() {
            if (document.getElementById('mainContent')) {
                document.getElementById('mainContent').style.height = '';
                document.getElementById('contentBox').style.paddingBottom = '';
                document.getElementById('mainContent').style.paddingBottom = '';
            }
            this.clearIntervalTimer();
            bus.$emit('stopFleetVideoLive');
            this.wsClose();
        },
        data() {
            return {
                apiDriverMap: null,
                vehicleList: {
                    map: new Map(),
                    list: []
                },
                intervalTimer: {
                    touch: null,
                    ping: null,
                    awsReconnect: null,
                },
                socketOption: {
                    token: null,
                    socket: null,
                    allData: [],
                    updateData: {},
                    collisionData: [],
                    twoWayCallData: {},
                    twoWayCallAnswerData: {},
                    lastAllMapData: new Map(),
                    currentCarData: {
                        kvsTtl: null,
                        speed: this.$t('globalText.na'),
                        fuel: this.$t('globalText.na'),
                        location: this.$t('globalText.na'),
                        timestamp: this.$t('globalText.na'),
                        collisionCount: 0,
                        driverAlertCount: 0,
                        parkingCollision: 0,
                        historyImg: null,
                        id:null
                    },
                    onerrorOption: {
                        times: 0,    // onError的次数
                        maxTimes: 3, // 允许出现onerror的最大次数
                        timer: null, // 处理onerror的定时器
                        hasShowMessageBox: false, // 是否展示彈窗
                    },
                },
                fleetOption: {
                    isOnRoad: true,
                    showType: 'fleet', // 地图展示的类型，car表示只展示一辆车；fleet表示展示车队
                    colorMap: new Map(), // FleetId => Color
                    deviceId: null,
                    data: {},
                    targetFleetCarIds: [],
                },
                mapOptions: {
                    zoom: 16,
                    zoomCenter: {value: null, center: {lat: null, lng: null}}, // 用于控制修改地图的zoom和中心
                    center: {
                        lng: this.$store.getters.companyInfo ? (this.$store.getters.companyInfo.Longitude ? this.$store.getters.companyInfo.Longitude : 121.59154): 121.59154,
                        lat: this.$store.getters.companyInfo ? (this.$store.getters.companyInfo.Latitude ? this.$store.getters.companyInfo.Latitude : 24.999): 24.999},
                    cars: [],
                    targetCar: {},
                    targetCarLocation: null, // 用于记录目标车辆的地址，当车辆触碰到边界时进行更新
                    boundary: [],
                    currentBoundaryTag: null, // 标记当前的boundary的id，用于控制多辆车同时出现在地图上
                    lastBoundaryTag: null,
                    currentSelectCarId: null,
                },
                messageOption: {
                    isShow: false,
                    showVideo: true,
                    showVoice: true,
                    data: {
                        vehicleName: null,
                        fleetName: null,
                        fleetTagColor: null,
                        plateNumber: null,
                        driverImg: null,
                        speed: null,
                        fuel: null,
                        location: null,
                        deviceId: null,
                    },
                    webRTC: {
                        id: null,
                        isBlink: false
                    }
                },
                collisionOption: {
                    isShow: false,
                    data: {}
                },
            }
        },
        computed: {
            wsAllData() {
                return this.socketOption.allData;
            },
            wsUpdateData() {
                return this.socketOption.updateData;
            },
            carId: {
                get() {
                    return this.fleetOption.deviceId;
                },
                set(id) {
                    this.fleetOption.deviceId = id;
                }
            },
            alertMessageData() {
                return this.messageOption.data;
            },
            globalFleetList() {
                return this.$store.state.fleetData.fleetList;
            },
            carIdToFleetColorMap() {
                let fleetData = this.$store.state.fleetData.fleetList;
                if (fleetData && fleetData.Sub) {
                    let map = new Map();
                    fleetData.Sub.forEach(item => {
                        if (item.Vehicles) {
                            item.Vehicles.forEach(vehicle => {
                                map.set(vehicle.DeviceId, item.Color);
                            })
                        }
                    });
                    return map;
                } else {
                    return false;
                }
            },
            targetFleetCarIds() {
                return this.fleetOption.targetFleetCarIds;
            }
        },
        watch: {
            globalFleetList(fleetList) {
                if (fleetList && fleetList.Sub) {
                    fleetList.Sub.forEach(item => {
                        this.fleetOption.colorMap.set(item.Id, item.Color);
                    });
                }
            },
            wsAllData() {
                this.socketOption.lastAllMapData.clear();
                this.socketOption.allData.forEach(item => {
                    item = this.checkCarOnLine(item);
                    item = this.transformGPSCoordinate(item);
                    this.socketOption.lastAllMapData.set(item.Id, item);
                });
                this.showCarsOnMap();
            },
            wsUpdateData(item) {
                item = this.checkCarOnLine(item);
                item = this.transformGPSCoordinate(item);
                this.updateLastAllMapData(item.Id, item);
                this.updateCurrentCarData();
                this.showCarsOnMap();
            },
            carId() {
                // this.messageOption.isShow = !!id;
                this.showCarsOnMap();
            },
            carIdToFleetColorMap() {
                this.showCarsOnMap();
            },
            targetFleetCarIds() {
                this.showCarsOnMap();
            }
        },
        methods: {
            handleWsKvsNoTime(deviceId) {
                this.socketOption.currentCarData.kvsTtl = 0;
                let wsData = this.socketOption.lastAllMapData.get(deviceId);
                if (wsData) {
                    wsData.KVSTTL = 0;
                    this.socketOption.currentCarData.kvsTtl = 0;
                    let tmp = this.socketOption.currentCarData;
                    this.socketOption.currentCarData = Object.assign({}, tmp);
                }
            },
            clearCurrentCarData() {
                this.socketOption.currentCarData = {
                    mainModel: '',
                    cameraIds: [],
                    has4GSignal: true,
                    vehicleName: '',
                    plateNumber: '',
                    kvsTtl: null,
                    speed: this.$t('globalText.na'),
                    fuel: this.$t('globalText.na'),
                    location: this.$t('globalText.na'),
                    timestamp: this.$t('globalText.na'),
                    collisionCount: 0,
                    driverAlertCount: 0,
                    parkingCollision: 0,
                    historyImg: null,
                    id:null
                };
            },
            handleCarReachTheBoundary(gps) {
                this.mapOptions.targetCarLocation = this.getGpsObj(gps.lat, gps.lng);
            },
            handleSelectCarFromMap(carId) {
                this.messageOption.isShow = true;
                this.mapOptions.currentSelectCarId = carId;
            },
            transformGPSCoordinate(item) {
                if (item.Latitude && item.Longitude) {
                    let newGps = CoordinateCovernt.gcj_encrypt(item.Latitude, item.Longitude);
                    item.Latitude_old = item.Latitude;
                    item.Longitude_old = item.Longitude;
                    item.Latitude = newGps.Latitude;
                    item.Longitude = newGps.Longitude;
                }
                return item;
            },
            updateCurrentCarData() {
                let wsData = this.socketOption.lastAllMapData.get(this.carId);
                if (wsData) {
                    let vehicleInfo = this.vehicleList.map.get(wsData.VehicleId);
                    this.socketOption.currentCarData = {
                        mainModel: wsData.MainModel || '',
                        cameraIds: wsData.CameraIds || [],
                        has4GSignal: !(wsData.Ago > 600),
                        vehicleName: vehicleInfo ? vehicleInfo.Name : '',
                        plateNumber: wsData.VehicleId,
                        kvsTtl: wsData.KVSTTL,
                        speed: wsData.Speed,
                        fuel: wsData.TotalFuelConsumed,
                        location: this.formatGpsAddress(wsData.Latitude, wsData.Longitude),
                        timestamp: wsData.Timestamp || 0,
                        collisionCount: wsData.CollisionCount || 0,
                        driverAlertCount: wsData.DriverAlertCount || 0,
                        parkingCollision: wsData.ParkingCollision || 0,
                        historyImg: wsData.DriverPicturePath,
                        id: wsData.VehicleId
                    };
                } else {
                    this.clearCurrentCarData();
                }
            },
            handleNodeClick(nodeData, isOnRoad) {
                this.fleetOption.isOnRoad = isOnRoad;
                this.mapOptions.targetCarLocation = null;
                bus.$emit('resetLiveTrackingButton');
                bus.$emit('pauseVideo');
                // bus.$emit('stopFleetVideoLive');
                if (nodeData.type === 'fleet') {
                    this.fleetOption.showType = 'fleet';
                    this.messageOption.isShow = false;
                    this.mapOptions.currentSelectCarId = null;
                    if (nodeData.parentId) {
                        this.mapOptions.currentBoundaryTag = nodeData.parentId;
                        if (nodeData && nodeData.Color && nodeData.Id) {
                            this.fleetOption.colorMap.set(nodeData.Id, nodeData.Color);
                        }
                        let carIds = [];
                        if (nodeData.fleetData && nodeData.fleetData.Vehicles) {
                            nodeData.fleetData.Vehicles.forEach(vehicle => {
                                if (vehicle.DeviceId) {
                                    carIds.push(vehicle.DeviceId);
                                }
                            });
                            this.fleetOption.targetFleetCarIds = carIds;
                            this.carId = null;
                        }
                    } else {
                        this.mapOptions.currentBoundaryTag = 0;
                        let carIds = [];
                        nodeData.fleetData.Sub.forEach(fleet => {
                            if (fleet.Color && fleet.Id) {
                                this.fleetOption.colorMap.set(fleet.Id, fleet.Color);
                            }
                            if (nodeData.fleetData && fleet.Vehicles) {
                                fleet.Vehicles.forEach(vehicle => {
                                    if (vehicle.DeviceId) {
                                        carIds.push(vehicle.DeviceId);
                                    }
                                });
                            }
                        });
                        this.fleetOption.targetFleetCarIds = carIds;
                        this.carId = null;
                    }
                } else if (nodeData.type === 'vehicle') {
                    if (this.carId !== nodeData.vehicleData.DeviceId) {
                        this.clearCurrentCarData();
                    }
                    this.messageOption.webRTC.id = nodeData.Id;
                    this.messageOption.webRTC.isBlink = nodeData.isCallRequest;
                    this.fleetOption.showType = 'car';
                    let data = nodeData.vehicleData;
                    if (nodeData.DeviceId && !data.DeviceId)
                        data.DeviceId = nodeData.DeviceId;
                    if (data && data.DeviceId) {
                        let wsData = this.socketOption.lastAllMapData.get(data.DeviceId);
                        if (!wsData) {
                            this.messageOption.isShow = false;
                            this.carId = null;
                            this.fleetOption.targetFleetCarIds = [];
                            return;
                        }
                        let driverImg = null;
                        let driverInfo = this.getDriverInfo(wsData.DriverId);
                        if (driverInfo.OtherPictures && driverInfo.OtherPictures[0]) {
                            driverImg = this.$baseURL + driverInfo.OtherPictures[0];
                        } else {
                            driverImg = null;
                        }
                        if (wsData.Latitude && wsData.Longitude) {
                            this.mapOptions.zoomCenter = {
                                value: 18,
                                center: {lat: wsData.Latitude, lng: wsData.Longitude}
                            };
                        }
                        this.fleetOption.targetFleetCarIds = [];
                        this.carId = data.DeviceId;
                        this.messageOption.isShow = true;
                        this.messageOption.showVideo = nodeData.isOnRoad;
                        this.messageOption.showVoice = nodeData.isOnRoad;
                        this.messageOption.data = {
                            deviceId: data.DeviceId,
                            vehicleId: data.Id,
                            vehicleName: data.Name,
                            fleetId: data.FleetId,
                            fleetName: data.FleetName,
                            fleetTagColor: data.Color,
                            plateNumber: wsData.VehicleId,
                            driverImg: driverImg,
                            driverId: driverInfo.Id,
                            firstName: driverInfo.FirstName,
                            lastName: driverInfo.LastName,
                            speed: wsData.Speed,
                            fuel: wsData.TotalFuelConsumed,
                            location: this.getGpsObj(wsData.Latitude, wsData.Longitude),
                            historyImg: wsData.DriverPicturePath || null,
                            timestamp: wsData.Timestamp || 0,
                            tripId: wsData.TripId,
                            isUnPermittedDriver:nodeData.isUnPermittedDriver
                        };
                    } else { // 车子没有安装device，不展示kvs及voice，属于Offload
                        let data = nodeData.vehicleData;
                        this.carId = null;
                        this.fleetOption.targetFleetCarIds = [];
                        this.messageOption.isShow = true;
                        this.messageOption.showVideo = false;
                        this.messageOption.showVoice = false;
                        this.messageOption.data = {
                            deviceId: data.DeviceId || '',
                            vehicleName: data.Name,
                            fleetName: data.FleetName,
                            fleetTagColor: data.Color,
                            plateNumber: data.Id,
                            driverImg: '',
                            speed: 0,
                            fuel: 0,
                            location: this.getGpsObj(0, 0),
                            historyImg: null,
                            timestamp: 0,
                        };
                    }
                    this.updateCurrentCarData();
                }
            },
            showCarsOnMap() {
                let cars = [], boundary = [], targetCar = {};
                let hasSocketTargetCar = this.socketOption.lastAllMapData.has(this.carId);
                this.socketOption.lastAllMapData.forEach(item => {
                    if (item && item.longitude !== 0 && item.Latitude !== 0) {
                        let fleetColor = item.StatusColor || this.fleetOption.colorMap.get(item.VehicleId);
                        if (this.carIdToFleetColorMap && this.carIdToFleetColorMap.has(item.Id)) {
                            fleetColor = this.carIdToFleetColorMap.get(item.Id);
                        }
                        if (this.fleetOption.showType === 'fleet') {
                            this.mapOptions.targetCar = {};
                            let index = this.fleetOption.targetFleetCarIds.findIndex(i => item.Id === i);
                            if (
                                index !== -1 &&
                                (
                                    (this.fleetOption.isOnRoad && item.OnRoad) ||
                                    (this.fleetOption.isOnRoad === false && item.OnRoad === false)
                                )
                            ) {
                                cars.push({
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.VehicleId,
                                    color: item.StatusColor,
                                    fleetColor: fleetColor
                                });
                                boundary.push({
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                })
                            }
                        } else if (this.fleetOption.showType === 'car') {
                            if (hasSocketTargetCar && this.carId && item.Id === this.carId) {
                                targetCar = {
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.VehicleId,
                                    color: item.StatusColor
                                };
                                cars.push({
                                    lng: item.Longitude,
                                    lat: item.Latitude,
                                    carId: item.VehicleId,
                                    color: item.StatusColor,
                                    fleetColor: fleetColor
                                });
                            }
                        }
                    }
                });
                if (this.fleetOption.showType === 'fleet' && this.mapOptions.currentBoundaryTag !== null && this.mapOptions.currentBoundaryTag !== this.mapOptions.lastBoundaryTag) {
                    this.mapOptions.boundary = boundary;
                    this.mapOptions.lastBoundaryTag = this.mapOptions.currentBoundaryTag;
                }
                this.mapOptions.targetCar = targetCar;
                this.mapOptions.cars = cars;
            },
            checkCarOnLine(item) {
                if (item.ACC === 1) {
                    item.StatusColor = item.Color || this.fleetOption.colorMap.get(item.VehicleId);
                    item.OnRoad = true;
                } else {
                    item.StatusColor = "#888";
                    item.OnRoad = false;
                }
                return item;
            },
            updateLastAllMapData(id, item) {
                let lastData = this.socketOption.lastAllMapData.get(id);
                // 如果给到的数据存在时序问题则过滤掉
                if (lastData) {
                    let oldTimestamp = lastData.Timestamp || 0,
                        newTimestamp = item.Timestamp || 0;
                    if (oldTimestamp > newTimestamp)
                        return;
                }
                for (let key in item) {
                    if (item.Longitude && item.Longitude === 0 && item.Latitude && item.Latitude === 0) {
                    } else {
                        if (item.hasOwnProperty(key) && lastData) lastData[key] = item[key];
                    }
                }
                if (lastData && !item.CollisionCount) {
                    lastData.CollisionCount = 0;
                }
                if (lastData && !item.ParkingCollision) {
                    lastData.ParkingCollision = 0;
                }
                if (lastData && !item.DriverAlertCount) {
                    lastData.DriverAlertCount = 0;
                }
                if (lastData && !item.DriverPicturePath) {
                    lastData.DriverPicturePath = null;
                }
                let newLastDatal = Object.assign({}, lastData);
                this.socketOption.lastAllMapData.set(id, newLastDatal);
            },
            handleCloseAlertMessage() {
                this.messageOption.isShow = false;
            },
            wsOpen() {
                let socketUrl = websocketConfig.url;
                this.socketOption.socket = new WebSocket(socketUrl);
                this.socketOption.socket.onopen = this.onOpen;
                this.socketOption.socket.onmessage = this.onMessage;
                this.socketOption.socket.onerror = this.onError;
                this.socketOption.socket.onclose = this.onClose;
            },
            wsClose() {
                if (this.socketOption.socket) {
                    this.socketOption.socket.close();
                    this.socketOption.socket = null;
                }
            },
            onOpen() {
                let msg = {
                    "action": "monitor",
                    token: this.socketOption.token,
                };
                msg = JSON.stringify(msg);
                this.socketOption.socket.send(msg);
                if (this.socketOption.onerrorOption.timer) clearTimeout(this.socketOption.onerrorOption.timer);
                this.socketOption.onerrorOption.timer = null;
                console.log('Live Tracking socket onOpen:', new Date().toString());
            },
            onMessage(event) {
                let obj = JSON.parse(event.data);
                switch (obj.action) {
                    case 'monitor-all':
                        obj.data.forEach(item => {
                            if (item.DeviceId) item.Id = item.DeviceId;
                        });
                        this.socketOption.allData = obj.data;
                        break;
                    case 'ping':
                        break;
                    case 'monitor-update':
                        if (obj.data.DeviceId) obj.data.Id = obj.data.DeviceId;
                        this.socketOption.updateData = obj.data;
                        this.checkRTCState(obj.data);
                        break;
                    case 'monitor-2WayCall':
                        this.socketOption.twoWayCallData = obj.data;
                        break;
                    case 'monitor-2WayCallAnswer':
                        this.socketOption.twoWayCallAnswerData = obj.data;
                        break;
                    default:
                        console.log('Live Tracking onMessage default data:', new Date().toString(), obj.data);
                        break;
                }
            },
            onClose() {
                console.log('Live Tracking socket onClose:', new Date().toString());
                if (!this.socketOption.onerrorOption.timer && this.$route.path === '/liveTracking') {
                    console.log('Live Tracking has closed, try reconnect:');
                    this.socketOption.onerrorOption.timer = setTimeout(() => {
                        this.socketOption.onerrorOption.timer = null;
                        this.wsClose();
                        if (this.getToken()) {
                            this.wsOpen();
                        } else {
                            console.error('Reconnect ws. Live Tracking websocket error -- token error!')
                        }
                    }, 5 * 1000);
                }
            },
            onError(e) {
                console.log('Live Tracking websocket Err:', new Date().toString(), '5 * 60 * 1000', e);
                if (this.socketOption.onerrorOption.hasShowMessageBox === false && this.socketOption.onerrorOption.times >= this.socketOption.onerrorOption.maxTimes) {
                    this.socketOption.onerrorOption.hasShowMessageBox = true;
                    this.$customAlert(this.$t('globalText.timeoutPleaseLoginAgain'), {
                        callback: () => {
                            this.$router.push({path: '/login'});
                        }
                    });
                    return;
                }
                if (!this.socketOption.onerrorOption.timer) {
                    this.socketOption.onerrorOption.times += 1;
                    console.log('Live Tracking websocket error, try times:', this.socketOption.onerrorOption.times);
                    this.socketOption.onerrorOption.timer = setTimeout(() => {
                        this.socketOption.onerrorOption.timer = null;
                        this.wsClose();
                        if (this.getToken()) {
                            this.wsOpen();
                        } else {
                            console.error('Live Tracking websocket error -- token error!')
                        }
                    }, 5 * 1000);
                }
            },
            checkRTCState(data){
                let isRunningRtc = this.$store.getters['websocket/getIsRunningRtcState'];
                let currentRtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
                if (isRunningRtc && data.ACC !==1 && data.DeviceId === currentRtcInfo.rtcConnectedDeviceId) {
                    bus.$emit('onDeviceOffRoad',data);//通知正在通话的车辆离线
                }
            },
            getToken() {
                this.socketOption.token = sessionStorage.getItem('token');
                return !!this.socketOption.token;
            },
            clearIntervalTimer() {
                clearInterval(this.intervalTimer.touch);
                clearInterval(this.intervalTimer.ping);
                clearInterval(this.intervalTimer.awsReconnect);
            },
            getGpsObj(latitude, longitude) {
                return {latitude: latitude, longitude: longitude};
            },
            formatGpsAddress(latitude, longitude) {
                if (latitude === 0 && longitude === 0) return this.$t('globalText.na');
                return 'GPS: ' + latitude + ', ' + longitude;
            },
            getDriverInfo(driverId) {
                if (this.apiDriverMap) {
                    if (this.apiDriverMap.has(driverId)) return this.apiDriverMap.get(driverId);
                    return {};
                }
                this.$serverApi.driver.driverList((data) => {
                    let map = new Map();
                    data.forEach((item) => {
                        map.set(item.Id, item);
                    });
                    this.apiDriverMap = map;
                });
                return {};
            },
            getVehicleList() {
                this.$serverApi.vehicles.vehicleList(data => {
                    this.vehicleList.list = data;
                    data.forEach(data => {
                        this.vehicleList.map.set(data.Id, data);
                    });
                });
            },
            handleCurrentVehicleChange() {
                this.messageOption.isShow = false;
                this.carId = null;
            },
            handleCallRequestStateChange(data) {
                /*
                  data :{
                        Id -----vehicle Id
                        isBlink --- true/false  if voice icon blink ?
                  }
                */
                if (data.Id === this.messageOption.webRTC.id)
                    this.messageOption.webRTC.isBlink = data.isBlink;
            }
        },
    }
</script>

<style scoped>
    .monitor-main-simple-monitor {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 800px;
        margin-top: -8px;

    }
</style>

<template>
    <div>
        <div class="msg-main-com-video">
            <speed-fuel-gps-box class="msg-video-sfg"
                                :on-road="true"
                                :timestamp="timestamp"
                                :fuel="convertFuel"
                                :fuel-unit="unitL"
                                :speed="convertSpeed"
                                :speed-unit="unitKmHr"
                                :location="address"
                                :location_bak2="realTimeData.location"/>
            <div v-show="(isShowKVSPlayer && (realTimeData.mainModel || '').indexOf('D700') === -1)">
                <div class="msg-camera-ids">
                    <el-select class="msg-select"
                               :disabled="isCameraSelectEnable"
                               @change="onCameraIdChange"
                               v-model="cameraId"
                               size="small"
                               placeholder="">
                        <!--M810设备的Camera  Index接口返回从1开始，但是kvs镜头需要从0开始-->
                        <el-option v-for="item in cameraIdList" :key="item.index"
                                   :label="$t('vehicle.camera')+' #' + (item.index + 1) + ' ' + item.name"
                                   :value="item.index + ''">
                        </el-option>
                    </el-select>
                </div>
                <div class="msg-camera-video">
                    <video-live-player
                            :camera-list="cameraIdList"
                            :open-full-screen="isShowFullScreen"
                            :camera-id="cameraId"
                            :device-id="deviceId"
                            @start-play="onPlayKVS"
                            @stop-play="onStopKVS"
                            @kvs-no-time="onKvsNoTime"
                            @normal-screen="onNormalScreen"/>
                    <div class="msg-fullscreen" @click.stop="onFullScreen">
                        <i class="el-icon-full-screen"/>
                    </div>
                </div>
            </div>
            <div v-show="isShowKVSPlayer && ((realTimeData.mainModel || '').indexOf('D700') !== -1)">
                <div class="msg-video-video">
                    <video-live-player
                            :has-send-command-on="liveVideoSendCommandOnStatus"
                            :open-full-screen="outsideCamera.isShowFullScreen"
                            :stream-name_bak="outsideCameraId"
                            :cameras="d700CameraIds"
                            camera-id="F"
                            :device-id="deviceId"
                            @start-play="handleD700StartPlayKvs"
                            @stop-play="handleStopPlayKvs"
                            @sendCommandOn="handleLiveVideoSendCommandOn"
                            @kvs-no-time="handleKvsNoTime"
                            @normal-screen="outsideCameraNormalScreen"/>
                    <div class="msg-video-video-fullscree" @click.stop="outsideCameraShowFullScreen">
                        <i class="el-icon-full-screen"/>
                    </div>
                </div>
                <div class="msg-video-video">
                    <video-live-player
                            :has-send-command-on="liveVideoSendCommandOnStatus"
                            :open-full-screen="insideCamera.isShowFullScreen"
                            :stream-name_bak="insideCameraId"
                            :cameras="d700CameraIds"
                            camera-id="R"
                            :device-id="deviceId"
                            @start-play="handleD700StartPlayKvs"
                            @stop-play="handleStopPlayKvs"
                            @sendCommandOn="handleLiveVideoSendCommandOn"
                            @kvs-no-time="handleKvsNoTime"
                            @normal-screen="insideCameraNormalScreen"/>
                    <div class="msg-video-video-fullscree" @click.stop="insideCameraShowFullScreen">
                        <i class="el-icon-full-screen"/>
                    </div>
                </div>
            </div>
            <div class="msg-no-video" v-show="!isShowKVSPlayer">
                <div v-if="!hasKVSTime"><i class="msg-tips via-warning"/>{{$t('liveTracking.KVSTimeUsedUp')}}</div>
                <div v-else><i class="msg-tips via-warning"/>{{$t('liveTracking.G4ConnectionLost')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoLivePlayer from "./VideoLivePlayer";
    import SpeedFuelGpsBox from "./SpeedFuelGpsBox";
    import bus from "../../../utils/bus";

    export default {
        name: "ComVideo",
        props: {
            deviceId: {
                type: String,
                required: true
            },
            speed: {
                type: Number,
                required: true
            },
            fuel: {
                required: true
            },
            address: {
                type: String,
                required: true
            },
            timestamp: {
                type: Number,
                required: true
            },
            realTimeData: {
                type: Object,
                required: true
            },
            cameraList: {
                required: true
            },
            isCameraSelectEnable:{
                required:true,
                type: Boolean
            }
        },
        components: {
            VideoLivePlayer,
            SpeedFuelGpsBox
        },
        beforeMount() {
            //this.hasKVSTime = !!this.realTimeData.kvsTtl && this.realTimeData.kvsTtl > 0;
            if (!this.realTimeData.kvsTtl) {
                //kvsttl字段不存在，此时认为kvs还有剩余时间。
                this.hasKVSTime = true;
            } else {
                this.hasKVSTime = this.realTimeData.kvsTtl !== 0;
            }
        },
        data() {
            return {
                hasKVSTime: true,
                liveVideoSendCommandOnStatus: false,
                d700CameraIds: [], // ['R', 'F']
                d700PlayingNum: 0, // 用于标记700在播放几个视频
                insideCamera: {
                    isShowFullScreen: false,
                    videoSource: 'D710_JP_Rear',
                },
                outsideCamera: {
                    isShowFullScreen: false,
                    videoSource: 'D710_JP_Rear',
                },
                hasCheckKvsTtl: false,
                isPlayingKvs: false,
                cameraId: '', // 列表形式的kvs使用
                isShowFullScreen: false, // 列表形式的kvs使用
            }
        },
        computed: {
            cameraIdList() {
                return this.cameraList || [];
            },
            isShowKVSPlayer() {
                return this.hasKVSTime && this.realTimeData.has4GSignal
            },
            kvsTtl() {
                return this.realTimeData.kvsTtl;
            },
            insideCameraId() {
                if (this.deviceId) {
                    return this.deviceId + '_R';
                }
                return '';
            },
            outsideCameraId() {
                if (this.deviceId) {
                    return this.deviceId + '_F';
                }
                return '';
            },
            unitL() {
                return this.$unit.getLUnit(this);
            },
            convertFuel() {
                if (isNaN(this.realTimeData.fuel) || typeof this.realTimeData.fuel !== 'number') return this.$t('globalText.na');
                // let num = this.$unit.convertLiterUnit(this.fuel);
                let num = this.$unit.convertLiterUnit(this.realTimeData.fuel);
                return (Math.round(num * 10) / 10).toFixed(1);
            },
            convertSpeed() {
                if (isNaN(this.realTimeData.speed) || typeof this.realTimeData.speed !== 'number') return this.$t('globalText.na');
                // let num = this.$unit.convertKilometerUnit(this.speed);
                let num = this.$unit.convertKilometerUnit(this.realTimeData.speed);
                return (Math.round(num * 10) / 10).toFixed(1);
            },
            unitKmHr() {
                return this.$unit.getKmHrUnit(this);
            },
        },
        watch: {
            cameraIdList(idList) {
                if (idList && idList.length > 0) {
                    if (!this.cameraId){
                        this.cameraId = idList[0].index + '';
                        console.error(`kkkkk,`,idList[0])
                    }


                } else {
                    this.cameraId = '';
                }
            },
            kvsTtl(time) {
                if (!time) {
                    this.hasKVSTime = true;
                }else {
                    this.hasKVSTime = time !== 0;
                }
                //this.hasKVSTime = !!time && time > 0;
            },
            deviceId() {
                this.hasCheckKvsTtl = false;
                this.isPlayingKvs = false;
                this.cameraId = '';
            }
        },
        methods: {
            onCameraIdChange() {
                this.stopAllKVSPlaying();
            },
            onPlayKVS(deviceId) {
                this.stopAllKVSPlaying();
                if (this.deviceId !== deviceId) return;
                let vehicleName = this.realTimeData.vehicleName,
                    plateNumber = this.realTimeData.plateNumber;
                this.$serverApi.device.kvsttl({deviceId: deviceId})
                    .then(data => {
                        if (this.deviceId !== deviceId) return;
                        this.showKVSTTLTips(data.KVSTTL, vehicleName, plateNumber);
                    });
            },
            onStopKVS(deviceId) {
                // if (this.deviceId !== deviceId) return;
            },
            onKvsNoTime() {
                this.handleKvsNoTime();
            },
            onNormalScreen() {
                this.isShowFullScreen = false;
            },
            onFullScreen() {
                this.isShowFullScreen = true;
            },
            stopAllKVSPlaying() {
                bus.$emit('stopFleetVideoLive')
            },
            showKVSTTLTips(KVSTTL, vehicleName, plateNumber) {
                if (KVSTTL && KVSTTL <= 30 * 60 && KVSTTL > 0) {
                    let msg = this.$t('popUpMsg.liveTracking.theKvsTimeRemainingFor_VehicleName_PlateNumber_Is_X_Seconds', {
                        vehicleName: vehicleName,
                        plateNumber: plateNumber,
                        seconds: KVSTTL
                    }); // `The KVS time remaining for ${vehicleName} (${plateNumber}) is ${data.KVSTTL} seconds.`
                    this.$customAlert(msg);
                }
            },
            handleD700StartPlayKvs(deviceId) {
                if (this.d700PlayingNum === 1 && this.deviceId === deviceId) {
                    this.d700CameraIds = [{Id: 'F'}, {Id: 'R'}];
                    return;
                }
                if (this.deviceId === deviceId)
                    this.d700PlayingNum += 1;
                if (this.hasCheckKvsTtl || this.deviceId !== deviceId) return;
                this.hasCheckKvsTtl = true;
                this.isPlayingKvs = true;
                let vehicleName = this.realTimeData.vehicleName,
                    plateNumber = this.realTimeData.plateNumber;
                this.$serverApi.device.kvsttl({deviceId: deviceId})
                    .then(data => {
                        if (this.deviceId !== deviceId || this.isPlayingKvs === false) return;
                        this.showKVSTTLTips(data.KVSTTL, vehicleName, plateNumber);
                    });
            },
            handleStopPlayKvs(deviceId) {
                if (this.deviceId !== deviceId) return;
                this.isPlayingKvs = false;
                this.d700CameraIds = [];
                this.d700PlayingNum = 0;
            },
            handleKvsNoTime() {
                this.hasKVSTime = false;
                this.$emit('update-ws-kvs-no-time', this.deviceId);
            },
            handleLiveVideoSendCommandOn(status) {
                this.liveVideoSendCommandOnStatus = !!status;
            },
            insideCameraShowFullScreen() {
                this.insideCamera.isShowFullScreen = true;
            },
            outsideCameraShowFullScreen() {
                this.outsideCamera.isShowFullScreen = true;
            },
            insideCameraNormalScreen() {
                this.insideCamera.isShowFullScreen = false;
            },
            outsideCameraNormalScreen() {
                this.outsideCamera.isShowFullScreen = false;
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $box-padding-right = 12px
    $box-padding-left = 12px
    .msg-main-com-video
        position relative
        padding 0 0 8px
        background-color $color_white

        .msg-video-sfg
            border-bottom 1px solid $color_gray_third

        .msg-video-btn-box
            padding 5px 15px 0

            .msg-video-video-no-voice:hover, .msg-video-video-refresh:hover
                cursor pointer

            .msg-video-video-title
                width 100%
                text-align center

        .msg-video-video-box
            padding 8px 12px 0

        .msg-camera-ids
            padding 20px 12px

            .msg-select
                width 100%

        .msg-camera-video
            padding 0 12px 10px

            .msg-fullscreen
                position absolute
                bottom 28px
                right 25px
                z-index 100
                color white
                font-size 16px
                cursor pointer

        .msg-no-video
            text-align center
            padding 214px 12px
            overflow hidden
            position relative

            .msg-tips
                color red
                display inline-block
                width 20px

        .msg-video-video
            padding 8px 12px 0
            overflow hidden
            position relative

            img
                width 100%
                height 100%

        .msg-video-video-fullscree
            1float right
            position absolute
            1top -25px
            bottom 10px
            right 25px
            z-index 100
            color white
            font-size 16px
            cursor pointer

        .msg-video-video-fullscree:hover
            color $color_blue_second
</style>

<template>
    <div class="layout-left-show-btn">
        <div class="show-btn-line"></div>
        <div class="show-btn-bg-hidden" :class="{'btn-open': isShowFleet, 'btn-close': !isShowFleet }"></div>
        <div class="ry-btn" v-if="isShowFleet"><i class="via-arrow-left"></i></div>
        <div class="ry-btn" v-else><i class="via-arrow-right"></i></div>
    </div>
</template>

<script>
    export default {
        name: "ComFleetShowBtn",
        props: {
            isShowFleet: {
                required: true,
                type: Boolean
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .layout-left-show-btn
        position relative
        text-align center
        line-height 0
        height 100%

        .btn-close
            border-left 1px solid $color_black_first

        .btn-open
            border-left 1px dotted $color_black_third

        .show-btn-line
            position absolute
            top -100vh
            left 0
            width 1px
            height 2000vh

        .show-btn-bg-hidden
            position absolute
            left 0
            height 100%
            width 1px

        .ry-btn
            margin 0 auto
            color $color_black_first
            position relative
            top 50%
            i
                font-size 20px
                position relative
                top -10px
        .ry-btn:hover
            color:$color_blue_second
</style>

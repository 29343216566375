<template>
    <div>
        <div class="msg-main-com-information">
            <top-driver class="msg-top-driver"
                        :driver-id="data.driverId"
                        :fleet-id="data.fleetId"
                        :vehicle-id="data.vehicleId"
                        :plate-number="data.plateNumber"
                        :first-name="data.firstName"
                        :last-name="data.lastName"
                        :driver-thumbnail="data.driverImg"
                        :fleet-name="data.fleetName"
                        :fleet-color="data.fleetTagColor"
                        :permitted="!data.isUnPermittedDriver"/>
            <speed-fuel-gps-box class="msg-sfg"
                                :on-road="onRoad"
                                :timestamp="data.timestamp"
                                :fuel="convertTotalFuelConsumption"
                                :fuel-unit="unitL"
                                :speed="convertAvgSpeedNum"
                                :speed-unit="unitKmHr"
                                :location="address"
                                :location_bak2="realTimeData.location"
                                :location_bak="data.location"/>
            <div class="msg-info-bottom">
                <img v-if="realTimeData.historyImg" :src="realTimeData.historyImg" ref="historyImg"
                     @error="handleImgError" width="100%"/>
                <div v-else class="msg-default-img">
                    <div class="msg-icon"><img src="../../../assets/image/photo-camera.svg" width="35"/></div>
                    <div>{{$t('globalText.uploading')}}</div>
                </div>
                <div v-if="showVoice" @click="onClickRTC"
                     :class="[enableHungup ? (realTimeData.has4GSignal ? 'msg-info-voice-box' : ('msg-info-voice-box disable-class')) : 'msg-info-voice-box disable-class']">
                    <!--<img v-if="defaultMicIconAnimation && !showVoiceDiv" :src="MicIconAni" alt="">
                    <span v-else-if="!showVoiceDiv || hasChangedDevice" class="via-microphone"></span>
                    <img v-else :src="micIcon" alt="" style="width:50px;margin-top: 5px">-->
                    <img :src="defaultMicIcon" alt="" :class="[defaultMicIcon == micIcon?'hangup':'']" @mouseover="onMouseOver" @mouseout="onMouseOut">
                </div>
                <!--<div id="master" style="display: none">
                    &lt;!&ndash;<div class="video-container">
                        <video ref="localStream" style="width: 100%" autoplay playsinline muted src="" disablePictureInPicture controlslist="nodownload"/>
                    </div>&ndash;&gt;
                    <div class="video-container">
                        <video src="" ref="remoteStream"  autoplay playsinline></video>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import TopDriver from "./TopDriver";
    import SpeedFuelGpsBox from "./SpeedFuelGpsBox";
    import MicIcon from '../../../assets/image/hangup.svg';
    import MicIcon1 from '../../../assets/image/MicIcon1.png'
    import MicIcon2 from '../../../assets/image/MicIcon2.png'
    import MicIcon3 from '../../../assets/image/MicIcon3.png'
    import MicIcon4 from '../../../assets/image/MicIcon4.png'
    import MicIcon5 from '../../../assets/image/MicIcon5.png'
    import MicIcon6 from '../../../assets/image/MicIcon6.png'
    import MicIcon7 from '../../../assets/image/MicIcon7.png'
    import MicIcon8 from '../../../assets/image/MicIcon8.png'
    import MicIcon9 from '../../../assets/image/MicIcon9.png'
    import MicIcon10 from '../../../assets/image/MicIcon10.png'
    import MicIcon11 from '../../../assets/image/MicIcon11.png'
    import MicIcon12 from '../../../assets/image/MicIcon12.png'

    export default {
        name: "ComInformation",
        props: {
            data: {
                type: Object,
                required: true
            },
            address: {
                type: String,
                required: true
            },
            showVoice: {
                type: Boolean,
                required: true
            },
            onRoad: {
                type: Boolean,
                required: true
            },
            realTimeData: {
                type: Object,
                required: true
            },
            isShowMicIconAnimation: {
                type: Boolean,
                default: false
            },
            deviceId: {
                type: String
            }
        },
        components: {
            TopDriver,
            SpeedFuelGpsBox
        },
        data() {
            return {
                defaultImg: require('../../../assets/image/image_error_holder.png'),
                micIcon: MicIcon,
                MicIconAni: MicIcon1,
                defaultMicIcon: MicIcon1,
                micIconTimer: null,
                defaultMicIconAnimation: false,
                hasChangedDevice: false
            }
        },
        watch: {
            isShowMicIconAnimation: {

                immediate: true,
                handler: function (val) {
                    console.log(`isShowMicIconAnimation = ${val}`);
                    this.defaultMicIconAnimation = val;

                }
            },
            showVoiceDiv() {
                let currentRtcInfo = this.$store.getters['websocket/getRtcTaskInfo'];
                console.log(`currentRtcInfo.deviceId = ${currentRtcInfo.deviceId},this.data.deviceId = ${this.data.deviceId}`);
                if (currentRtcInfo && currentRtcInfo.deviceId === this.data.deviceId) {
                    if (this.micIconTimer) {
                        clearInterval(this.micIconTimer);
                        this.micIconTimer = null;
                    }

                    if (this.showVoiceDiv && currentRtcInfo.deviceId === this.data.deviceId ) {
                        this.defaultMicIcon = this.micIcon;
                    } else {
                        this.defaultMicIcon = MicIcon1;
                    }

                    //挂断电话时showVoiceDiv值发生变化，置为false，此时检查defaultMicIconAnimation值，为true则继续动画，否则停止动画
                    if (!this.showVoiceDiv && this.defaultMicIconAnimation) {
                        console.log(`继续动画`);
                        this.doMicIconAnimation()
                    }
                }
            },
            defaultMicIconAnimation: {
                immediate: true,
                handler: function (val) {
                    if (val) {
                        this.doMicIconAnimation()
                    } else {
                        let currentRtcInfo = this.$store.getters['websocket/getRtcTaskInfo'];
                        if (currentRtcInfo && currentRtcInfo.deviceId === this.data.deviceId && this.showVoiceDiv){
                            //正在通话、通话对象是当前车辆。此时发生状态变化不修改话筒状态
                            console.log(`忽略当次状态变化`);
                            return
                        }
                        this.defaultMicIcon = MicIcon1;
                        if (this.micIconTimer) {
                            clearInterval(this.micIconTimer);
                            this.micIconTimer = null;
                        }
                    }

                }
            },
            deviceId: {
                immediate:true,
                handler:function () {
                    this.defaultMicIcon = MicIcon1;
                    if (this.micIconTimer) {
                        clearInterval(this.micIconTimer);
                        this.micIconTimer = null;
                    }
                    let rtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
                    if (this.showVoiceDiv) {
                        if (rtcInfo.rtcConnectedDeviceId === this.data.deviceId) {
                            this.defaultMicIcon = this.micIcon;
                        } else {
                            if (this.defaultMicIconAnimation) {
                                this.doMicIconAnimation()
                            } else {
                                this.defaultMicIcon = MicIcon1;
                            }
                        }
                    } else {
                        if (this.defaultMicIconAnimation) {
                            this.doMicIconAnimation()
                        } else {
                            this.defaultMicIcon = MicIcon1;
                        }
                    }
                }

            }
        },
        computed: {
            unitL() {
                return this.$unit.getLUnit(this);
            },
            unitKmHr() {
                return this.$unit.getKmHrUnit(this);
            },
            convertAvgSpeedNum() {
                if (isNaN(this.realTimeData.speed) || typeof this.realTimeData.speed !== 'number') return this.$t('globalText.na');
                let value = this.$unit.convertKilometerUnit(this.realTimeData.speed);
                return this.numRound(value, 1);
            },
            convertTotalFuelConsumption() {
                let fuel = this.realTimeData.fuel;
                if (isNaN(this.realTimeData.fuel) || typeof this.realTimeData.fuel !== 'number') fuel = this.$t('globalText.na');
                let value = this.$unit.convertLiterUnit(fuel);
                return this.numRound(value, 1);
            },
            showVoiceDiv() {
                return this.$store.getters['websocket/getIsRunningRtcState'];
            },
            enableHungup() {
                return this.$store.getters['websocket/getHangUpIconStatus'];
            },
        },
        mounted() {
            if (this.isShowMicIconAnimation) {
                this.doMicIconAnimation()
            }
        },
        methods: {
            onClickRTC() {
                if (!this.realTimeData.has4GSignal) {
                    //选中车辆没有4G信号，处理离线状态
                    return
                }

                if (!this.enableHungup) {
                    //当前rtc初始化未完成，不允许进行操作
                    return
                }
                localStorage.removeItem("rtcInfo");
                //localStorage中储存rtc信息，供上传音频接口使用。不能使用state中的信息，因为state中的信息在通话结束时会被清除掉
                let storageInfo = {
                    DeviceId:this.data.deviceId,
                    TripId:this.data.tripId,
                    FleetId:this.data.fleetId,
                    PlateNumber:this.data.plateNumber
                };
                localStorage.setItem("rtcInfo",JSON.stringify(storageInfo));
                let driverName = this.data.firstName ? this.data.firstName + " " + this.data.lastName : 'UnKnown';
                let currentRtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
                if (this.showVoiceDiv && currentRtcInfo.rtcConnectedDeviceId !== this.data.deviceId) {
                    //当前有正在进行的RTC通话
                    this.$customConfirm('Terminate the call to <strong>' + currentRtcInfo.rtcConnectedDriverName +
                        '</strong></br>and call <strong>' + driverName + '</strong> ?', {
                        dangerouslyUseHTMLString: true
                    }).then(() => {
                        this.$store.commit('websocket/setRtcTask', {
                            type: null,
                            deviceId: null,
                            driverName: null,
                            vehicleId: null,
                            currentTime: new Date().getTime(),
                            otherRtcTask: {
                                type: '重拨任务',
                                deviceId: this.data.deviceId,
                                driverName: driverName,
                                vehicleId: this.data.plateNumber,
                            }
                        });
                        if (this.micIconTimer) {
                            clearInterval(this.micIconTimer);
                            this.micIconTimer = null;
                        }
                        this.defaultMicIcon = this.micIcon;
                    }).catch(() => {
                        console.log('取消');
                    });
                } else {
                    this.$store.commit('websocket/setRtcTask', {
                        type: 'ComInformation',
                        deviceId: this.data.deviceId,
                        driverName: driverName,
                        vehicleId: this.data.plateNumber,
                        currentTime: new Date().getTime()
                    })
                }
            },
            numRound(value, point) {
                if (isNaN(value)) return value;
                let numTmp = Math.pow(10, point);
                return (Math.round(value * numTmp) / numTmp).toFixed(1);
            },
            handleImgError() {
                let img = this.$refs.historyImg;
                if (img) {
                    img.src = this.defaultImg;
                    img.onerror = null;
                }
            },
            doMicIconAnimation() {
                let currentRtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
                if (this.showVoiceDiv && currentRtcInfo.rtcConnectedDeviceId === this.data.deviceId) {
                    //正在通话时收到当前车辆动画状态改变通知，此时不做状态修改，通话结束时会修改对应状态
                    return
                }
                let count = 0;
                if (this.micIconTimer) {
                    clearInterval(this.micIconTimer);
                    this.micIconTimer = null;
                }
                this.micIconTimer = setInterval(() => {
                    let temp = count % 11;
                    if (temp === 0) {
                        this.defaultMicIcon = MicIcon1;
                    } else if (temp === 1) {
                        this.defaultMicIcon = MicIcon2;
                    } else if (temp === 2) {
                        this.defaultMicIcon = MicIcon3;
                    } else if (temp === 3) {
                        this.defaultMicIcon = MicIcon4;
                    } else if (temp === 4) {
                        this.defaultMicIcon = MicIcon5;
                    } else if (temp === 5) {
                        this.defaultMicIcon = MicIcon6;
                    } else if (temp === 6) {
                        this.defaultMicIcon = MicIcon7;
                    } else if (temp === 7) {
                        this.defaultMicIcon = MicIcon8;
                    } else if (temp === 8) {
                        this.defaultMicIcon = MicIcon9;
                    } else if (temp === 9) {
                        this.defaultMicIcon = MicIcon10;
                    } else if (temp === 10) {
                        this.defaultMicIcon = MicIcon11;
                    } else {
                        this.defaultMicIcon = MicIcon12;
                    }
                    count++;
                }, 83);
            },
            onMouseOver(){
                if (this.micIconTimer || !this.enableHungup || this.defaultMicIcon === this.micIcon) return;
                this.defaultMicIcon = MicIcon12
            },
            onMouseOut(){
                if (this.micIconTimer || !this.enableHungup || this.defaultMicIcon === this.micIcon) return;
                this.defaultMicIcon = MicIcon1
            }

        },
        beforeDestroy() {
            if (this.micIconTimer) {
                clearInterval(this.micIconTimer);
                this.micIconTimer = null;
            }

        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $box-padding-right = 12px
    $box-padding-left = 12px
    .msg-main-com-information
        position relative
        background-color $color_white
        padding 0

        .msg-top-driver, .msg-sfg
            border-bottom 1px solid $color_gray_third

        .msg-info-bottom
            position relative
            min-height 100px
            padding-top 24px
            padding-bottom 35px
            padding-left $box-padding-left
            padding-right $box-padding-right

            .msg-default-img
                background-color $color_gray_first
                height 167px
                text-align center
                font-size 18px
                line-height 18px
                color $color_black_third
                cursor default

                .msg-icon
                    text-align center
                    display block
                    padding-top 40px
                    margin 0 auto
                    height 35px
                    padding-bottom 12px

            .msg-info-voice-box
                position absolute
                bottom 0
                left calc(50% - 35px)
                width 70px
                height 70px
                line-height 70px
                border-radius 70px
                cursor pointer
                font-size 40px
                color $color_black_first
                background-color #ffffff
                text-align center

            > .msg-info-text
                position absolute
                bottom 0
                text-decoration underline
                cursor pointer

            .video-container
                background black
                display inline-block
                line-height 0
                max-width 100%
                width 100%

        .via-icon
            color $color_black_first

        .disable-class
            cursor not-allowed !important
        .hangup
            width: 50px
            margin-top: 5px
</style>

<template>
    <div class="ry-main" ref="ryFullScreen" id="ryFullScreen">
        <div class="ry-map-box ry-transition" ref="ryMapBox" id="ryMapBox">
            <slot name="map"></slot>
        </div>
        <div class="ry-fleet-show-btn ry-transition" ref="ryFleetShowBtn" id="ryFleetShowBtn"
             @click="handleIsShowFleet">
            <com-fleet-show-btn :is-show-fleet="isShowFleet"></com-fleet-show-btn>
        </div>
        <div class="ry-left ry-transition" ref="ryLeft" id="ryLeft">
            <slot name="left"></slot>
        </div>
        <div class="ry-bottom ry-transition" ref="ryBottom" id="ryBottom">
            <slot name="bottom"></slot>
        </div>

        <slot></slot>
    </div>
</template>

<script>
    import ComFleetShowBtn from '../../components/monitor/ComFleetShowBtn';
    import bus from "../../utils/bus";

    export default {
        name: "ComLayout",
        components: {
            ComFleetShowBtn,
        },
        mounted() {
            try {
                let fatherBoxEl = document.getElementById('mainContentContent');
                fatherBoxEl.style.position = 'absolute';
                fatherBoxEl.style.left = '5px';
                fatherBoxEl.style.right = '0px';
                fatherBoxEl.style.top = '0px';
                fatherBoxEl.style.bottom = '0px';
                fatherBoxEl.style.padding = '0px';
            } catch (e) {
            }

            bus.$on('handleIsShowFullScreen', this.handleIsShowFullScreen);
            bus.$on('handleBottomHeightStyle', this.handleBottomHeightStyle);
        },
        data() {
            return {
                isShowFleet: true,   // 是否展示车队box
                isFullScreen: false, // 是否展示全屏，隐藏左侧导航和上边的导航
            }
        },
        methods: {
            handleIsShowFleet() {
                let leftWidth = this.isShowFleet ? 0 : 300;
                this.$refs.ryLeft.style.width = leftWidth + 'px';
                this.$refs.ryBottom.style.left = leftWidth + 'px';
                this.$refs.ryFleetShowBtn.style.left = (leftWidth === 0 ? 0 : leftWidth + 10) + 'px';
                let leftBorderWidth = this.isShowFleet ? 0 : 10;
                this.$refs.ryLeft.style.borderWidth = leftBorderWidth + 'px';
                // let self=this
                // let fleetShowBtnWidth = 20; // 同步修改css的值
                // setTimeout(function () {
                //     self.$refs.ryMapBox.style.left = (leftWidth + fleetShowBtnWidth) + 'px';
                // }, 100);
                this.isShowFleet = !this.isShowFleet;
            },
            handleBottomHeightStyle(style) {
                // let time=100;
                // let self=this;
                if (style === 'fullScreen') {
                    this.$refs.ryBottom.style.bottom = '0px';
                    this.$refs.ryBottom.style.height = '100%';
                    // this.$refs.ryFleetShowBtn.style.bottom = '200px';
                } else if (style === 'min') {
                    this.$refs.ryBottom.style.bottom = '0px';
                    this.$refs.ryBottom.style.top = '';
                    this.$refs.ryBottom.style.height = '40px';
                    // this.$refs.ryFleetShowBtn.style.bottom = '0px';
                    // setTimeout(function () {
                    //     self.$refs.ryMapBox.style.bottom = '40px';
                    // }, time);
                } else if (style === 'normal') {
                    this.$refs.ryBottom.style.bottom = '0px';
                    this.$refs.ryBottom.style.top = '';
                    this.$refs.ryBottom.style.height = '200px';
                    // this.$refs.ryFleetShowBtn.style.bottom = '200px';
                    // setTimeout(function () {
                    //     self.$refs.ryMapBox.style.bottom = '200px';
                    // }, time);
                }
            },
            handleIsShowFullScreen() {
                if (this.isFullScreen) {
                    this.$refs.ryFullScreen.style.position = 'relative';
                } else {
                    this.$refs.ryFullScreen.style.position = 'fixed';
                }
                this.isFullScreen = !this.isFullScreen;
                bus.$emit('LayoutIsFullScreen', this.isFullScreen);
            }
        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color = $color_gray_third
    $left-box-width = 300px
    $left-box-border-width = 10px
    $left-border-color = $color_black_first
    $bottom-box-height = 200px
    $bottom-box-height = 0px
    $transition-time = .2s
    $fleet-show-btn-width = 30px // 需要同步修改js中值

    $background-color-map-box = rgba(255, 255, 255, 1)
    $background-color-fleet-show-btn = $color_gray_third;
    $background-color-left = rgba(255, 255, 255, 1)
    $background-color-bottom = rgba(255, 255, 255, 1)

    .ry-main .ry-fleet-show-btn /deep/ .ry-main
        background-color $background-color-fleet-show-btn

    .ry-main
        position relative
        top 0px
        left 0px
        width 100%
        height 100%
        z-index 100
        background-color $color_white
        overflow hidden

        .ry-transition
            transition-duration $transition-time
            -moz-transition-duration $transition-time /* Firefox 4 */
            -webkit-transition-duration $transition-time /* Safari 和 Chrome */
            -o-transition-duration $transition-time

        /* Opera */

        .ry-map-box, .ry-fleet-show-btn, .ry-fleet-show-btn, .ry-left, .ry-bottom
            overflow hidden
            position absolute

        .ry-fleet-show-btn
            overflow visible

        .ry-map-box
            background-color $background-color-map-box
            z-index -1
            position absolute
            top 0px
            left $left-box-width + $fleet-show-btn-width
            left $fleet-show-btn-width
            left 0px
            right 0px
            bottom $bottom-box-height

        .ry-fleet-show-btn
            background-color $background-color-fleet-show-btn
            color $color_black_first
            position absolute
            top 53px
            height 60px
            left $left-box-width + $left-box-border-width
            width $fleet-show-btn-width
            line-height 70px
            z-index 1010
            border-top-right-radius 10px
            border-bottom-right-radius 10px
            border-right 1px solid $left-border-color
            border-top 1px solid $left-border-color
            border-bottom 1px solid $left-border-color

            >>> .show-btn-line
                border-left 1px solid $left-border-color

            >>> .show-btn-bg-hidden
                background-color $background-color-fleet-show-btn

        /*border-image linear-gradient(to left,#b0a681,#fff) 2 10;*/

        .ry-fleet-show-btn-bak:after
            content ''
            position absolute
            top 0px
            left 0px
            right 0px
            bottom 0px
            z-index -1
            background: linear-gradient(90deg, #fff, #b0a681);

        .ry-fleet-show-btn:hover
            cursor pointer

        .ry-left
            border-right $left-box-border-width solid $color_gray_third
            color $color_black_first
            background-color $background-color-left
            position relative
            float left
            overflow hidden
            width $left-box-width
            height 100%
            z-index 1010

        .ry-bottom
            z-index 1010
            background-color $background-color-bottom
            left $left-box-width
            overflow hidden
            position absolute
            bottom 0px
            right 0px
            height $bottom-box-height
</style>
<template>
    <div class="msg-main-com-top">
        <span class="com-msg-top-title" :title="title">{{ title }}</span>
        <span class="com-msg-top-btn" @click="handleClick"><i class="el-icon-close"></i></span>
    </div>
</template>

<script>
    export default {
        name: "ComTop",
        props: {
            title: {
                type: String,
                required: true
            }
        },
        methods: {
            handleClick() {
                this.$emit('close');
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .msg-main-com-top
        position relative
        color $color_white
        height 40px
        background-color $color_blue_first

        .com-msg-top-title
            font-size 16px
            line-height 40px
            display inline-block

        .com-msg-top-title
            display inline-block
            float left
            padding-left 12px
            width calc(100% - 70px)
            overflow: hidden;
            text-overflow ellipsis
            white-space nowrap

        .com-msg-top-btn
            float right
            height 40px
            font-size 22px
            line-height 40px
            padding-right 12px

            :hover
                cursor pointer
                color $color_blue_second

    .msg-main-com-top:hover
        cursor move
</style>
<template>
    <div v-if="!showAvatar">
        <div v-if="driverThumbnail" class="td-photo-box" @mouseenter="showPreview" @mouseleave="hidePreview">
            <el-image :src="driverThumbnail" width="100%" @error="handleError">
                <img slot="error" src="../../../assets/image/photo-camera.svg" width="50%"/>
            </el-image>
            <div class="td-img-preview" v-if="isShowPreviewBtn && !loadImageError">
                <i class="el-icon-zoom-in" @click="previewImage"></i>
            </div>
        </div>
        <div v-else class="td-photo-box">
            <img src="../../../assets/image/photo-camera.svg" width="50%"/>
        </div>
        <div v-if="driverId && driverId !=='0'" @click="linkToViewDriver" class="td-driver-name dp-link" :title="driverName" :class="{'unknown-name':(driverName =='Unknown' || permitted === false)}">{{driverName}}</div>
        <div v-else class="td-driver-name" :title="driverName" :class="{'unknown-name':driverName =='Unknown'}">{{$t('globalText.unknown')}}</div>
        <preview-dialog v-model="isShowPreviewDialog" :img-path="driverThumbnail">
        </preview-dialog>
    </div>
    <div v-else>
        <div class="td-img-box">
            <el-image v-if="driverThumbnail" :src="driverThumbnail" width="100%">
                <img slot="error" src="../../../assets/image/default-photo.svg" width="100%"/>
            </el-image>
            <img v-else src="../../../assets/image/default-photo.svg" width="100%"/>
        </div>
        <div class="td-driver-name" :title="driverName" :class="{'unknown-name':(driverName =='Unknown' || permitted === false)}">
            <span v-if="driverId  && driverId !=='0'" @click="linkToViewDriver" class="dp-link">{{driverName}}</span>
            <span v-else>{{$t('globalText.unknown')}}</span>
        </div>
    </div>

</template>

<script>
    import PreviewDialog from "@/components/common/PreviewDialog";

    export default {
        name: "DriverPhoto",
        components: {PreviewDialog},
        props: {
            permitted: {
                type: Boolean,
                default: true
            },
            driverId: {
                type: [String, Number]
            },
            driverThumbnail: {
                type: [String, Object, Boolean]
            },
            driverName: {type: String},
            showAvatar: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isShowPreviewBtn: false,
                isShowPreviewDialog: false,
                loadImageError: false
            }
        },
        watch: {
            driverThumbnail(val) {
                this.loadImageError = false;
            }
        },
        methods: {
            showPreview() {
                this.isShowPreviewBtn = true;
            },
            hidePreview() {
                this.isShowPreviewBtn = false;
            },
            previewImage() {
                this.isShowPreviewDialog = true;
            },
            handleError() {
                this.loadImageError = true;
            },
            linkToViewDriver() {
                console.log(this.driverId)
                this.$router.push({name: 'viewDriver', query: {id: this.driverId}});
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .dp-link:hover
        cursor pointer
        color $color_blue_second

    .td-photo-box
        border-radius 5px
        width 96px
        height 54px
        overflow hidden
        margin 0 auto
        text-align center
        display: flex;
        justify-content: center;
        align-items: center;
        border 1px solid $color_gray_third

        > img
            border-radius 5px
            height auto

        > .td-img-preview
            position: absolute;
            top: 0;
            left: 0;
            width: 96px
            height: 54px
            line-height: 54px
            cursor pointer
            background: rgba(0, 0, 0, 0.35);
            color: white;
            font-size: 18px;
            text-align: center;
            border-radius: 5px;

    .td-img-box
        border-radius 100%
        width 48px
        height 48px
        overflow hidden
        margin 0 auto
        border 1px solid $color_gray_third

    .td-driver-name
        text-align center
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        padding-top 6px

    .unknown-name
        color $color_collision
</style>

<template>
    <el-dialog
            :width="width"
            :visible.sync="showPreviewDialog"
            custom-class="driver-preview-dlg"
            :before-close="handleClose"
               append-to-body>
            <img :src="isError ? defaultImage : imgPath">
    </el-dialog>
</template>

<script>
    export default {
        name: "PreviewDialog",
        data() {
            return {
                showPreviewDialog: false,
                defaultImage: require('../../assets/image/AlertImgDefaultPic.png')
            }
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            imgPath: {
                type: [String, Object, Boolean]
            },
            width:{
                type: String
            },
            isError:{
                type: Boolean,
                default: false
            }
        },
        watch: {
            value(val) {
                this.showPreviewDialog = val;
            }
        },
        methods: {
            handleClose() {
                this.$emit('input', false)
            }
        },
        created() {
            this.showPreviewDialog = this.value;
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    >>> .driver-preview-dlg
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*height:600px;*/
        max-height: calc(100% - 30px);
        max-width: calc(100% - 30px);
        text-align center
        border-radius 5px
        img
            width: 100%;
            vertical-align: middle;
            border-radius 5px

    >>> .el-dialog__headerbtn
        height: 40px;
        line-height: 40px;
        width: 40px;
        border-radius: 100%;
        background: white;
        position: absolute;
        top: -20px;
        right: -20px;
        padding: 0;
        border: none;
        outline: 0;
        cursor: pointer;
        font-size: 22px;

    >>> .el-dialog__close
        color $color_blue_first !important
        font-weight bold !important

    >>> .el-dialog__header, >>> .el-dialog__body {
        padding: 0;
    }

</style>

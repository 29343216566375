<template>
    <div class="left-tree-content">
        <div class="filter-area" style="text-align: center">
            <el-radio-group v-model="radioVal" @change="treeFilterChange" size="small" style="width: 100%">
                <el-radio-button label="On-road">{{$t('liveTracking.onRoad')}}</el-radio-button>
                <el-radio-button label="Off-road">{{$t('liveTracking.offRoad')}}</el-radio-button>
            </el-radio-group>
        </div>
        <v-tree :is-on-rode-filter="treeFilterArr" ref="vtree"
                class="tree-area"
                :default-node-key="treeDefaultId"
                :is-show-state="true"
                :all-state-info="allStateInfo"
                :update-state-info="updateStateInfo"
                :call-request-state-info="callRequestStateInfo"
                :show-number="true"
                :isSetDefaultClickNode="true"
                :is-show-un-assigned-vehicle="true"
                @node-click="handleTreeNodeClick"
                @currentVehicleChange="handleCurrentVehicleChange"
                @callRequestStateChange="handleCallRequestStateChange">
        </v-tree>
    </div>
</template>

<script>
    import vTree from '../../components/common/Tree'

    export default {
        name: "ComLeftTree",
        props: {
            wsAllData: {
                type: Array,
                required: true
            },
            wsUpdateData: {
                type: Object,
                required: true
            },
            wsCollisionData: {
                type: Array
            },
            wsTwoWayCallData: {
                type: Object
            },
            wsTwoWayCallAnswerData: {
                type: Object
            },
            id: {
                type: String
            }
        },
        components: {
            vTree
        },
        data() {
            return {
                treeFilterArr: {
                    prop: 'isOnRoad',
                    key: true,
                    type: 0
                },
                defaultTreeId: null,
                radioVal: 'On-road',
                allStateInfo: null,
                updateStateInfo: null,
                callRequestStateInfo: null
            }
        },
        computed: {
            isOnRoad() {
                return this.radioVal === 'On-road';
            },
            treeDefaultId() {
                return this.$store.getters.CurrentOnRoadTreeNodeId;
            }
        },
        watch: {
            wsAllData: {
                handler: function (val) {
                    //console.log(`wsAllData`, val);
                    if (val)
                        this.allStateInfo = val.map((item) => {
                            /*if(item.PlateNumber === "866567"){
                                console.log("all data 866567 ---");
                                console.log(JSON.stringify(item));
                            }*/
                            return {
                                Id: item.PlateNumber,
                                isNoSignal: item.Ago > 600 ? true : false,
                                isCollision: (item.CollisionCount && (item.CollisionCount > 0)) ? true : false,
                                isDriverAlert: (item.DriverAlertCount && (item.DriverAlertCount > 0)) ? true : false,
                                isUnPermittedDriver: (item.UnpermittedDriver && (item.UnpermittedDriver)) > 0 ? true : false,
                                isParkingCollision: (item.ParkingCollision && (item.ParkingCollision > 0)) ? true : false,
                                isOnRoad: item.ACC == 1 ? true : false,
                                DriverId: item.DriverId,
                                DeviceId: item.DeviceId
                            }
                        });
                }
            },
            wsUpdateData: {
                handler: function (val) {
                    this.updateStateInfo = {
                        Id: val.PlateNumber,
                        isNoSignal: val.Ago > 600 ? true : false,
                        isCollision: (val.CollisionCount && (val.CollisionCount > 0)) ? true : false,
                        isDriverAlert: (val.DriverAlertCount && (val.DriverAlertCount > 0)) ? true : false,
                        isUnPermittedDriver: (val.UnpermittedDriver && (val.UnpermittedDriver)) > 0 ? true : false,
                        isParkingCollision: (val.ParkingCollision && (val.ParkingCollision > 0)) ? true : false,
                        isOnRoad: val.ACC == 1 ? true : false,
                        DriverId: val.DriverId,
                        DeviceId: val.DeviceId
                    };
                }
            },
            wsTwoWayCallData: {
                handler: function (val) {
                    this.callRequestStateInfo = {
                        Id: val.VehicleId,
                        isCallRequestAnswered: false,
                        TTL: val.TTL
                    }
                    console.log("this.callRequestStateInfo " + JSON.stringify(this.callRequestStateInfo));
                }
            },
            wsTwoWayCallAnswerData: {
                handler: function (val) {
                    this.callRequestStateInfo = {
                        Id: val.VehicleId,
                        isCallRequestAnswered: true
                    }
                    console.log("this.callRequestStateInfo " + JSON.stringify(this.callRequestStateInfo));
                }
            },
            id: function (val) {
                if (val) {
                    this.selectOnRoadVehicle(val)
                }

            },
            treeDefaultId: {
                    immediate: true,
                    handler: function (val) {
                        if (val) {
                            if(this.radioVal != 'On-road'){
                                this.radioVal = 'On-road';
                                this.treeFilterChange();
                            }
                        }
                    }
                }

        },
        methods: {
            treeFilterChange() {
                if (this.radioVal === 'On-road' || this.radioVal === 'Off-road') {
                    this.treeFilterArr = {
                        prop: 'isOnRoad',
                        key: this.radioVal == 'On-road' ? true : false,
                        type: 0
                    }
                } else {
                    this.treeFilterArr = {};
                }
            },
            handleTreeNodeClick(data) {
                this.$emit("node-click", data, this.isOnRoad);
            },
            handleCurrentVehicleChange() {
                this.$emit("currentVehicleChange");
            },
            handleCallRequestStateChange(data) {
                this.$emit("callRequestStateChange", data);
            },
            selectOnRoadVehicle(id) {
                if (this.$refs.vtree) {
                    this.$refs.vtree.selectNodeById(id);
                }
            }
        },
        create() {
        }

    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .left-tree-content {
        padding: 10px 0 0 0;
        background-color: $color_gray_third;
        height: 100%;
    }

    .filter-area {
        margin-bottom: 10px;
    }

    .tree-area {
        border-radius: 0;
        border: 1px $color_gray_third solid;
    }

    >>> .el-radio-button {
        width: 50%;
    }

    >>> .el-radio-button__inner {
        width: 100%;
        font-size: 14px;
        background-color: white;
        border-color: white;
        -webkit-box-shadow: -1px 0 0 0 white;
        box-shadow: -1px 0 0 0 white;

    }
</style>

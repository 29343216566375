<template>
    <div class="top-driver">
        <div class="td-row" :title="this.plateNumber || $t('globalText.unknown')">
            {{$t('liveTracking.plateNumber')}}:
            <span class="td-link" v-if="vehicleId && canRouteToVehicleInfo" @click="linkToViewVehicle">{{this.plateNumber || $t('globalText.unknown')}}</span>
            <span v-else>{{this.plateNumber || $t('globalText.unknown')}}</span>
        </div>
        <div class="td-row" :title="fleetName || $t('liveTracking.unassigned')">
            <span class="td-name">{{$t('liveTracking.fleet')}}:&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="td-icon via-vehicles " :style="{ color: fleetColor || '' }"/>
            <span v-if="fleetId && fleetName" class="td-link" @click="linkToFleetList">{{fleetName}}</span>
            <span v-else>{{fleetName || $t('liveTracking.unassigned')}}</span>
        </div>
        <driver-photo
                :permitted="permitted"
                :driver-id="driverId"
                :driver-thumbnail="driverThumbnail"
                :driver-name="driverName"
                :show-avatar="showAvatar"
                class="td-driver-box">
        </driver-photo>
    </div>
</template>

<script>
    import DriverPhoto from "../alertMessage/DriverPhoto";

    export default {
        name: "TopDriver",
        components: {DriverPhoto},
        props: {
            permitted: {
                type: Boolean,
                default: true
            },
            driverId: {
                type: [String, Number],
            },
            fleetId: {
                type: [String, Number],
            },
            vehicleId: {
                type: [String, Number],
            },
            plateNumber: {
                type: String,
            },
            fleetColor: {
                type: String
            },
            fleetName: {
                type: String
            },
            driverThumbnail: {
                type: [String, Object, Boolean]
            },
            firstName: {
                type: String
            },
            lastName: {
                type: String
            },
            fullName: {
                type: String
            },
            showAvatar: {
                type: Boolean,
                default: true
            },
            canRouteToVehicleInfo: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            driverName() {
                if (this.fullName) return this.fullName;
                if (this.firstName && this.lastName) return this.firstName + ' ' + this.lastName;
                return this.$t('globalText.unknown');
            },
            driver() {
                return {
                    OtherPictures: this.driverThumbnail,
                    FirstName: this.firstName,
                    LastName: this.lastName
                }
            }
        },
        methods: {
            linkToViewVehicle() {
                this.$router.push({name: 'viewVehicle', query: {id: this.vehicleId}});
            },
            linkToFleetList() {
                this.$router.push({name: 'fleetList', query: {fleetId: this.fleetId}});
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $box-padding-left = 12px
    $box-padding-right = 12px
    .top-driver
        cursor default
        position relative
        background-color #ffffff
        padding-right $box-padding-right
        padding-left $box-padding-left
        padding-bottom 5px
        padding-top 20px

        .td-link:hover
            cursor pointer
            color $color_blue_second

        > .td-row
            width calc(100% - 100px)
            height 32px
            line-height 20px
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            padding-right 5px

            > .td-name, > .td-value
                display inline-block

            > .td-icon
                display inline-block
                font-size 20px
                margin-left 4px
                vertical-align middle

        > .td-driver-box
            position absolute
            top 8px
            width 100px
            right $box-padding-right

            > .td-img-box
                border-radius 5px
                width 96px
                height 54px
                overflow hidden
                margin 0 auto
                text-align center
                display: flex;
                justify-content: center;
                align-items: center;
                border 1px solid $color_gray_third

                > img
                    border-radius 5px
                    height auto

                > .td-img-preview
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 96px
                    height: 54px
                    line-height: 54px
                    background: rgba(0, 0, 0, 0.35);
                    color: white;
                    font-size: 18px;
                    text-align: center;
                    border-radius: 5px;

            > .td-driver-name
                text-align center
                overflow hidden
                text-overflow ellipsis
                white-space nowrap
                padding-top 8px

</style>

<template>
    <div class="msg-main-com-button">
        <div class="com-btn-name">{{ currentI18nName }}</div>
        <div class="com-btn-box">
            <div v-if="showInformation" :class="{'active-btn':this.activeBtn === 'information'}"
                 @click="setActive('information')"><span class="via-info"></span></div>
            <div v-if="showCollision" :class="{'active-alarm-btn':this.activeBtn === 'collision'}"
                 @click="setActive('collision')"><span class="via-alarm"></span></div>
            <div v-if="showVideo" :class="{'active-btn':this.activeBtn === 'video'}"
                 @click="setActive('video')"><span class="via-live-view"></span></div>
            <div v-if="showVoice" :class="{'active-btn':this.activeBtn === 'call'}"
                 @click="setActive('call')"><span class="via-microphone"></span></div>
        </div>
    </div>
</template>
<script>
    import bus from "../../../utils/bus";

    export default {
        name: "ComButtons",
        props: {
            name: {
                type: String,
                required: true
            },
            showInformation: {
                type: Boolean,
                default: true
            },
            showCollision: {
                type: Boolean,
                default: false
            },
            showVoice: {
                type: Boolean,
                default: false
            },
            showVideo: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                currentName: 'Information',
                activeBtn: '',
            }
        },
        computed: {
            currentI18nName() {
                let name;
                switch (this.currentName) {
                    case "2-Way Call":
                        name = this.$t('liveTracking.way2Call');
                        break;
                    case "Information":
                        name = this.$t('liveTracking.information');
                        break;
                    case "Alerts":
                        name = this.$t('liveTracking.alerts');
                        break;
                    case "Live Video":
                        name = this.$t('liveTracking.liveVideo');
                        break;
                    default:
                        name = this.$t('liveTracking.information');
                        break;
                }
                return name;
            }
        },
        mounted() {
            bus.$on('resetLiveTrackingButton', this.resetLiveTrackingButton)
            this.init(this.name);
        },
        methods: {
            setActive: function (name) {
                this.init(name);
                this.$emit('activeType', name);
                if (this.activeBtn !== 'video') {
                    bus.$emit('stopFleetVideoLive')
                }
                if (this.activeBtn !== 'collision') {
                    bus.$emit('pauseVideo');
                }
            },
            init(name) {
                switch (name) {
                    case 'call':
                        this.currentName = '2-Way Call';
                        this.activeBtn = 'call';
                        break;
                    case 'information':
                        this.currentName = 'Information';
                        this.activeBtn = 'information';
                        break;
                    case 'collision':
                        this.currentName = 'Alerts';
                        this.activeBtn = 'collision';
                        break;
                    case 'video':
                        this.currentName = 'Live Video';
                        this.activeBtn = 'video';
                        break;
                    default:
                        this.currentName = 'Information';
                        this.activeBtn = 'information';
                }
            },
            resetLiveTrackingButton() {
                this.setActive('information');
            }
        },
        watch: {
            name: function () {
                this.init(this.name);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .msg-main-com-button
        position relative
        border-bottom 1px solid $color_gray_third
        background-color $color_white
        height 47px
        padding-top 10px

        .com-btn-name
            padding-left 12px
            font-size 18px
            line-height 37px
            float left
            color $color_black_first
            font-weight 600

        .com-btn-box
            float right
            padding-right 12px

            div
                float left
                width 32px
                height 32px
                line-height 32px
                border $color_gray_third 1px solid
                border-radius 5px
                margin-left 6px
                text-align center
                font-size 16px

            div:hover
                cursor pointer
                background $color_blue_hover

            .active-btn
                color white
                background-color $color_blue_first !important

            .active-alarm-btn
                color white
                background-color $color_collision

            .active-alarm-btn:hover
                background-color #ff0000
</style>

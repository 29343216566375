<template>
    <div class="msg-main-com-alert-message-v2" ref="ryDragBoxMsg">
        <div class="msg-main-com">
            <com-top class="top-blue-border" v-drag="{that: this}" :title="data.vehicleName" @close="handleClose"/>
            <com-buttons class="grey-border-left grey-border-right"
                         :name="name"
                         :show-video="showVideo"
                         :show-collision="showCollisionBtn"
                         @activeType="handleActiveType"/>
            <com-information
                    class="grey-border-left grey-border-right grey-border-bottom"
                    v-show="this.currentType === 'information'"
                    :on-road="onRoad"
                    @current-alert="currentAlert"
                    :show-voice="showVoice"
                    :data="data"
                    :deviceId="data.deviceId"
                    :address="address"
                    :is-show-mic-icon-animation="isShowMicIconAnimation"
                    :real-time-data="realTimeData"/>
            <div class="amv-new-event-tips"
                 @click="updateAlertList"
                 v-show="this.currentType === 'collision' && newAlert.show">
                <div class="amv-content">{{ newAlert.name }} <span class="el-icon-refresh"/></div>
            </div>
            <trip-collision class="grey-border-left grey-border-right grey-border-bottom"
                            v-show="this.currentType === 'collision'" :event-list="collisionList">
                <div class="amv-loading" v-show="collisionList.length === 0">
                    <div class="amv-img-box" v-show="apiCollisionStatus === 'Loading'">
                        <img src="../../assets/image/video_equipment.svg" width="40px"/>
                        <div class="amv-text">{{$t('globalText.loading')}}</div>
                    </div>
                    <div class="amv-img-box" v-show="apiCollisionStatus === 'NoData' || apiCollisionStatus === 'Error'">
                        <img src="../../assets/image/video_equipment.svg" width="40px"/>
                        <!--                        <div class="amv-text">Uploading</div>-->
                        <div class="amv-text">{{$t('globalText.loading')}}</div>
                    </div>
                </div>
            </trip-collision>
            <com-video class="grey-border-left grey-border-right grey-border-bottom"
                       :device-id="data.deviceId"
                       :fuel="data.fuel"
                       :target-car-location="targetCarLocation"
                       :speed="data.speed"
                       :address="address"
                       :timestamp="data.timestamp"
                       :real-time-data="realTimeData"
                       :camera-list="cameraList"
                       :is-camera-select-enable="isCameraSelectEnable"
                       @update-ws-kvs-no-time="handleKvsNoTime"
                       v-show="this.currentType === 'video'"/>
        </div>
    </div>
</template>

<script>
    import ComTop from './alertMessage/ComTop';
    import ComButtons from './alertMessage/ComButtons';
    import ComInformation from './alertMessage/ComInformation';
    import ComVideo from './alertMessage/ComVideo';
    import TripCollision from "./alertMessage/TripCollision";
    import bus from "../../utils/bus";
    import {collisionVideoImageKeyConversion, getCameraFunName} from "../../assets/js/Util";

    export default {
        name: "ComAlertMessageV2",
        props: {
            data: {
                type: Object,
                required: true
            },
            collisionData: {
                type: Object,
                required: true
            },
            showCollision: {
                type: Boolean,
                required: true
            },
            showVideo: {
                type: Boolean,
                required: true
            },
            showVoice: {
                type: Boolean,
                required: true
            },
            onRoad: {
                type: Boolean,
                required: true
            },
            realTimeData: {
                type: Object,
                required: true
            },
            targetCarLocation: {
                type: [Object, null]
            },
            isShowMicIconAnimation: {
                type: Boolean,
                default: false
            },
            /*localStream: MediaStream,
            remoteStream: MediaStream*/
        },
        components: {
            ComTop,
            ComButtons,
            ComInformation,
            ComVideo,
            TripCollision
        },
        mounted() {
            this.$nextTick(() => {
                this.updateAddress();
            });
        },
        data() {
            return {
                isCameraSelectEnable: false,
                currentType: 'information',
                name: "information",
                collisionList: [],
                apiCollisionStatus: 'Loading', // 值分别为：Loading、NoData、Error,
                address: '',
                newAlert: {
                    show: false,
                    name: this.$t('dashboard.newAlert')
                },
                cameraList: null
            }
        },
        computed: {
            showCollisionBtn() {
                let realTimeData = this.realTimeData;
                if (this.onRoad) {
                    return realTimeData.collisionCount > 0
                        || realTimeData.driverAlertCount > 0;
                } else {
                    return realTimeData.parkingCollision === 1;
                }
            },
            currentGps() {
                if (this.targetCarLocation) return this.targetCarLocation;
                return this.data.location;
            },
            collisionCount() {
                return this.realTimeData.collisionCount;
            },
            driverAlertCount() {
                return this.realTimeData.driverAlertCount;
            },
            parkingCollision() {
                return this.realTimeData.parkingCollision;
            }
        },
        watch: {
            currentType(val) {
                if (val === 'video') {
                    this.isCameraSelectEnable = true;
                    let cameraListTemp = [];
                    this.cameraList = [];
                    this.$serverApi.vehicles.getVehicle(this.realTimeData.id, data => {
                        if (!data.isD700 && !data.isD700S) {
                            if (data.Device) {
                                Object.keys(data.Device.CameraTypes).forEach(item =>{
                                    cameraListTemp.push({CameraIndex:item - 1, CameraValue: data.Device.CameraTypes[item]})
                                })
                                cameraListTemp.forEach(item => {
                                    let resultObj = getCameraFunName(item.CameraValue);
                                    if (resultObj.result) {
                                        this.cameraList.push({
                                            index: item.CameraIndex,
                                            name: this.$t('vehicle.' + resultObj.name)
                                        });
                                    }else {
                                        this.cameraList.push({
                                            index: item.CameraIndex,
                                            name: resultObj.name
                                        });
                                    }
                                });

                            }
                            this.isCameraSelectEnable = false;
                        } else {
                            this.cameraList = [];
                            this.isCameraSelectEnable = true;
                        }

                    })
                }
            },
            collisionCount(count) {
                if (count > 0) {
                    this.newAlert.show = true;
                    this.newAlert.name = this.$t('dashboard.newCollisionAlert');
                }
            },
            driverAlertCount(count) {
                if (count > 0) {
                    this.newAlert.show = true;
                    this.newAlert.name = this.$t('dashboard.newDriverAlert');
                }
            },
            parkingCollision(count) {
                if (count > 0) {
                    this.newAlert.show = true;
                    this.newAlert.name = this.$t('dashboard.newParkingAlert');
                }
            },
            currentGps() {
                this.updateAddress();
            }
        },
        methods: {
            handleKvsNoTime(deviceId) {
                this.$emit('update-ws-kvs-no-time', deviceId);
            },
            updateAddress() {
                this.address = '';
                let gps = this.currentGps;
                this.$Gps.getLocation(gps.latitude, gps.longitude, false).then((location) => {
                    this.address = location;
                }).catch((location) => {
                    this.address = location;
                });
            },
            handleClose() {
                this.$emit('close');
            },
            updateAlertList() {
                this.collisionList = [];
                this.handleActiveType('collision');
            },
            handleActiveType(type) {
                this.currentType = type;
                this.name = type;
                if (this.showCollisionBtn) {
                    if (type === 'collision') {
                        this.newAlert.show = false;
                        if (this.onRoad === false && this.realTimeData.parkingCollision === 1) {
                            this.getCurrentParkingAlertList(this.data.vehicleId);
                        } else {
                            this.getCurrentCollisionList(this.data.tripId);
                        }
                    } else {
                        bus.$emit('pauseVideo');
                        this.collisionList = [];
                    }
                }
            },
            currentAlert(id) {
                this.currentType = 'alarm';
                this.name = 'alarm';
            },
            getCurrentCollisionList(tripId) {
                this.apiCollisionStatus = 'Loading';
                this.$serverApi.collision.tripList({tripId: tripId}, (data) => {
                    if (this.data.tripId === tripId) {
                        let newData = [];
                        data.forEach(item => {
                            if (item.Event !== 'Unpermitted')
                                newData.push(item);
                        });
                        this.apiCollisionStatus = newData.length === 0 ? 'NoData' : '';
                        let tempArr = newData.sort((a, b) => {
                            return a.Timestamp - b.Timestamp;
                        });
                        this.collisionList = collisionVideoImageKeyConversion(tempArr);
                    }
                }, () => {
                    this.collisionList = [];
                    this.apiCollisionStatus = 'Error';
                });
            },
            getCurrentParkingAlertList(vehicleId) {
                this.apiCollisionStatus = 'Loading';
                this.$serverApi.collision.parkingAlertList({vehicleId: vehicleId}, (data) => {
                    if (this.data.vehicleId === vehicleId) {
                        let newData = [];
                        data.forEach(item => {
                            if (item.Event === 'ParkingCollision')
                                newData.push(item);
                        });
                        this.apiCollisionStatus = newData.length === 0 ? 'NoData' : '';
                        this.collisionList = newData.sort((a, b) => {
                            return a.Timestamp - b.Timestamp;
                        });
                    }
                }, () => {
                    this.collisionList = [];
                    this.apiCollisionStatus = 'Error';
                });
            }
        },
        directives: {
            drag(el, binding) {
                el.onmousedown = (e) => {
                    let elBox = binding.value.that.$refs.ryDragBoxMsg;
                    let oriX = e.pageX;
                    let oriY = e.pageY;
                    let documentWidth = document.getElementById('ryFullScreen').clientWidth;
                    let documentHeight = document.getElementById('ryFullScreen').clientHeight;
                    let leftWidth = document.getElementById('ryLeft').clientWidth;
                    let btnWidth = document.getElementById('ryFleetShowBtn').clientWidth;
                    btnWidth = 0;
                    let bottomHeight = document.getElementById('ryBottom').clientHeight;
                    let dragBoxWidth = documentWidth - leftWidth - btnWidth;
                    let dragBoxHeight = documentHeight - bottomHeight;
                    let selfWidth = el.clientWidth;
                    let selfHeight = el.clientHeight;
                    let curX = elBox.style.right.replace('px', '');
                    let curY = elBox.style.top.replace('px', '');
                    curX = curX === '' ? 60 : parseInt(curX);
                    curY = curY === '' ? 0 : parseInt(curY);
                    document.onmousemove = function (e) {
                        let xOffset = oriX - e.pageX;
                        let yOffset = e.pageY - oriY;
                        let newX = curX + xOffset;
                        let newY = curY + yOffset;
                        newX = newX < 0 ? 0 : newX;
                        newY = newY < 0 ? 0 : newY;
                        newX = newX < (dragBoxWidth - selfWidth) ? newX : (dragBoxWidth - selfWidth);
                        newY = newY < (dragBoxHeight - selfHeight) ? newY : (dragBoxHeight - selfHeight);
                        elBox.style.right = newX + 'px';
                        elBox.style.top = newY + 'px';
                    }
                    document.onmouseup = function () {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .msg-main-com-alert-message-v2
        position absolute
        right 60px
        top 15px
        width 320px

        .msg-main-com
            position relative
            height 100%
            width 100%
            overflow hidden

        .top-blue-border
            border-left 1px solid $color_blue_first
            border-right 1px solid $color_blue_first

        .grey-border-left
            border-left 1px solid $color_gray_third

        .grey-border-right
            border-right 1px solid $color_gray_third

        .grey-border-bottom
            border-bottom 1px solid $color_gray_third

        .amv-new-event-tips
            cursor pointer
            z-index 1
            position absolute
            width calc(100% - 2px)
            border-left 1px solid $color_gray_third
            border-right 1px solid $color_gray_third
            border-bottom 1px solid $color_gray_third
            left 0

            .amv-content
                text-align center
                padding 5px 10px

        .amv-loading
            text-align center
            padding 8px 12px

            .amv-img-box
                height 140px
                padding-top 60px
                background-color $color_gray_first
                color $color_black_third

                .amv-text
                    font-size 18px
                    padding-top 8px
</style>
<style scoped>
    .amv-new-event-tips {
        animation: bgswitch 1s infinite;
        animation-direction: alternate;
        -webkit-animation: bgswitch 1s infinite;
    }

    @keyframes bgswitch {
        0% {
            background-color: #fff;
        }
        25% {
            background-color: #fff;
        }
        50% {
            background-color: #ffe3e3;
        }
        75% {
            background-color: #ffe3e3;
        }
        100% {
            background-color: #ffe3e3;
        }
    }

    @-webkit-keyframes bgswitch {
        0% {
            background-color: #fff;
        }
        25% {
            background-color: #fff;
        }
        50% {
            background-color: #ffe3e3;
        }
        75% {
            background-color: #ffe3e3;
        }
        100% {
            background-color: #ffe3e3;
        }
    }
</style>
